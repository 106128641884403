import React from "react";
import styled, { keyframes } from "styled-components";
import { HeadingMunicipality, Caption } from "../../Util/StyleProps";
import BubbleFlexSvg from "../Elements/BubbleFlexSvg";
import { Col } from "react-bootstrap";
import { fadeIn, slideInUp } from "react-animations";
import { createMarkup } from "../../Helpers/CommonHelper";
import { scaleLinear } from "d3-scale";

const slideInAnimation = keyframes`${slideInUp}`;
const fadeInAnimation = keyframes`${fadeIn}`;

const Wrapper = styled.div`
  // margin-top: -30px;
`;

const Svg = styled.svg`
  width: 100%;
`;

const SvgBottom = styled.svg`
  margin-top: 0;
`;

const G = styled.g``;

const Rect = styled.rect`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  animation: 0.75s ${props => (props.triggeranimation ? slideInAnimation : "")};
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
`;

const SingleBarSvg = props => {
  const {
    valuePercentage,
    label,
    delay,
    triggerAnimation,
    color,
    title,
    titleId,
    lineType,
    caption,
    desc,
    descId,
    viewBoxWidth,
    viewBoxHeight,
    topSpace,
    maxOffset,
    hasBubbleBg,
    valueString
  } = props;

  const y = scaleLinear()
    .domain([0, maxOffset])
    .nice()
    .range([0, viewBoxHeight - topSpace]);

  const rectangleWidth = 50;
  return (
    <Wrapper>
      <Svg
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        // aria-labelledby={titleId + " " + descId}
      >
        {/* <title id={titleId}>{title}</title>
          <desc id={descId}>{desc}</desc> */}
        <G>
          <BubbleFlexSvg
            triggerAnimation={triggerAnimation}
            hasBackground={hasBubbleBg}
            delay={0.5 + delay}
            x={0}
            y={viewBoxHeight - topSpace * 0.8 - y(Number(valuePercentage))}
            label={label}
            value={valueString}
            lineType={lineType}
            color={color}
            width={"100%"}
            viewBoxWidth={120}
            viewBoxHeight={140}
            // hasLine={true}
          />
          <Rect
            triggeranimation={triggerAnimation}
            delay={delay}
            x={rectangleWidth * 0.5}
            y={viewBoxHeight - y(Number(valuePercentage))}
            height={triggerAnimation ? y(Number(valuePercentage)) : 0}
            fill={color}
            width={rectangleWidth}
          ></Rect>
        </G>
      </Svg>
      <Caption
        dangerouslySetInnerHTML={createMarkup(caption)}
        color={color}
        paddingLeft={"24.8%"}
      ></Caption>
    </Wrapper>
  );
};

SingleBarSvg.defaultProps = {
  caption: "stapeltext",
  title: null,
  hasBubbleBg: true,
  lineType: null,
  topSpace: 90
};

export default SingleBarSvg;
