import React from "react";
import BlockLayout from "./../Layout/BlockLayout";
import { Container, Row, Col } from "react-bootstrap";
import ComponentHeader from "../Header/ComponentHeader";
import image from "../../assets/img/onion.png";
import SectionImageBetween from "../Elements/SectionImageBetween";
import { useInView } from "react-intersection-observer";
import SurfaceArea from "../Elements/SurfaceArea";
import KeyFigure from "../Elements/KeyFigure";
import { headingSmall } from "../../Util/StyleProps";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import { Straighten } from "@material-ui/icons/";
import styled from "styled-components";
import {
  sectionLandSizeColor,
  sectionPopulationLocationColor
} from "../../Util/Colors";
import municipalityimage1 from "../../assets/img/stork.png";
import map from "../Map/swedenmuni";
import municipalityimage2 from "../../assets/img/stork.png";

const Heading = styled.h2`
  font-size: 20px;
  font-family: PT Serif;
  line-height: 28px;
  font-style: normal;
  font-weight: bold;
`;
const Heading4 = styled.h4`
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
  font-style: normal;
  font-weight: bold;
`;
const Line = styled.hr`
  height: auto;
  border: 1px solid #595959;
  margin-top: 10px;
  margin-bottom: 40px;
  opacity: 0.5;
`;

const LandSizeContainer = props => {
  const [ref, inView, entry] = useInView({
    /* Optional options */

    threshold: 0
  });
  return (
    <React.Fragment>
      <BlockLayout
        background={`linear-gradient(to bottom, ${sectionLandSizeColor} 100% , ${sectionLandSizeColor} 100%`}
      >
        <ComponentHeader
          heading={"Så stor är vår yta"}
          text={"Sveriges totala yta är 450 295 kvm2"}
        />
        <Row>
          <Col item xs={6} sm={6} md={4} lg={3}>
            <SurfaceArea
              triggeranimation={inView}
              image={municipalityimage2}
              height={50}
              width={137}
              color={secondaryColor}
              delay={1.75}
              value={2159}
            ></SurfaceArea>
          </Col>
          <div ref={ref}></div>
          <Col item xs={6} sm={6} md={4} lg={3}>
            <KeyFigure
              triggeranimation={inView}
              delay={0.75}
              icon={Straighten}
              color={primaryColor}
              size={headingSmall}
              value={"689"}
              subText={"meter mellan grannar"}
              municipalityname="Stockholm"
            />
          </Col>

          <Col item xs={6} sm={6} md={4} lg={3}>
            <KeyFigure
              triggeranimation={inView}
              delay={1.75}
              icon={Straighten}
              color={secondaryColor}
              size={headingSmall}
              value={"450"}
              subText={"meter mellan grannar"}
              municipalityname="Göteborg"
            />
          </Col>
        </Row>
      </BlockLayout>
      <SectionImageBetween
        image={image}
        topcolor={sectionLandSizeColor}
        bottomcolor={sectionPopulationLocationColor}
      />
    </React.Fragment>
  );
};
export default LandSizeContainer;
