import React from "react";
import BlockLayout from "./../Layout/BlockLayout";
import ComponentHeader from "../Header/ComponentHeader";
import KeyFigureNew from "../Elements/KeyFigureNew";
import { useInView } from "react-intersection-observer";
import {
  headingSmall,
  Line,
  FiguresWrapper,
  HeadingMunicipality
} from "../../Util/StyleProps";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import { calculatePercentage, periodToComma } from "../../Helpers/CommonHelper";
import SvgLayout from "./../Layout/SvgLayout";
import EmptySpace from "../Elements/EmptySpace";

const LandUseContainer = props => {
  const { data, sweden, blockColor, scroll } = props;
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0
  });

  let municipality1 = data[0];
  let municipality2 = data[1];

  let forestPercentage = 0;
  let agriculturePercentage = 0;
  let settelmentPercentage = 0;
  let otherPercentage = 0;
  let yearSweden = sweden.landuse ? sweden.landuse.year : "";

  if (sweden.landuse) {
    forestPercentage = calculatePercentage(
      sweden.landuse.forest,
      sweden.landuse.total
    );
    agriculturePercentage = calculatePercentage(
      sweden.landuse.agriculture,
      sweden.landuse.total
    );
    settelmentPercentage = calculatePercentage(
      sweden.landuse.settlements,
      sweden.landuse.total
    );
    otherPercentage = calculatePercentage(
      sweden.landuse.other,
      sweden.landuse.total
    );
  }
  //I hela Sverige används 2,9 procent av marken till bebyggelse. 69,1 procent är skog, 7,5 procent är jordbruksmark, och 20,6 procent är övrig mark (2015).

  let swedenString = `I hela Sverige används ${periodToComma(
    settelmentPercentage
  )} procent av marken till bebyggelse.  ${periodToComma(
    forestPercentage
  )} procent är skog, ${periodToComma(
    agriculturePercentage
  )} procent är jordbruksmark, 
   och ${periodToComma(otherPercentage)} procent är övrig mark (${yearSweden}).`;

  // Accessibility
  const ariaLabel = municipality => {
    return municipality
      ? `I ${municipality.name} kommun används ${calculatePercentage(
          municipality.settlements,
          municipality.total
        )} procent av marken till bebyggelse.
      ${calculatePercentage(
        municipality.forest,
        municipality.total
      )} procent är skog, ${calculatePercentage(
          municipality.agriculture,
          municipality.total
        )} procent är jordbruksmark, 
      och ${calculatePercentage(
        municipality.other,
        municipality.total
      )} procent är övrig mark (${yearSweden}).`
      : "";
  };

  return (
    <React.Fragment>
      <BlockLayout background={blockColor}>
        <ComponentHeader heading={"Så används marken"} text={swedenString} />
        {municipality1 ? (
          <div ref={ref}>
            <FiguresWrapper maxWidth="1000px">
              {municipality1 ? (
                <SvgLayout width="50%">
                  <HeadingMunicipality
                    color={primaryColor}
                    aria-label={ariaLabel(municipality1)}
                  >
                    {municipality1.name}
                  </HeadingMunicipality>
                  <SvgLayout paddingTop="18px">
                    <KeyFigureNew
                      triggerAnimation={inView}
                      delay={0.75}
                      iconName={"house"}
                      alt="Ikon hus"
                      color={primaryColor}
                      size={headingSmall}
                      value={calculatePercentage(
                        municipality1.settlements,
                        municipality1.total
                      )}
                      subText={"bebyggelse"}
                      unit={"%"}
                    />
                    <KeyFigureNew
                      triggerAnimation={inView}
                      delay={0.75}
                      iconName={"nature"}
                      alt="Ikon skog"
                      color={primaryColor}
                      size={headingSmall}
                      value={calculatePercentage(
                        municipality1.forest,
                        municipality1.total
                      )}
                      subText={"skog"}
                      unit={"%"}
                    />
                    <KeyFigureNew
                      triggerAnimation={inView}
                      delay={0.75}
                      iconName={"spa"}
                      alt="Icon sädesslag"
                      color={primaryColor}
                      size={headingSmall}
                      value={calculatePercentage(
                        municipality1.agriculture,
                        municipality1.total
                      )}
                      subText={"jordbruksmark"}
                      unit={"%"}
                    />
                    <KeyFigureNew
                      triggerAnimation={inView}
                      delay={0.75}
                      iconName={"stripes"}
                      alt="Ikon ränder"
                      color={primaryColor}
                      size={headingSmall}
                      value={calculatePercentage(
                        municipality1.other,
                        municipality1.total
                      )}
                      subText={"övrig mark"}
                      unit={"%"}
                    />
                  </SvgLayout>
                </SvgLayout>
              ) : (
                <div></div>
              )}
              <Line marginBottom="36px" />
              {municipality2 ? (
                <SvgLayout width="50%" marginLeft="30px">
                  <HeadingMunicipality
                    color={secondaryColor}
                    aria-label={ariaLabel(municipality2)}
                  >
                    {municipality2.name}
                  </HeadingMunicipality>
                  <SvgLayout paddingTop="18px">
                    <KeyFigureNew
                      triggerAnimation={inView}
                      delay={1.75}
                      iconName={"house"}
                      alt="Ikon hus"
                      color={secondaryColor}
                      size={headingSmall}
                      value={calculatePercentage(
                        municipality2.settlements,
                        municipality2.total
                      )}
                      subText={"bebyggelse"}
                      unit={"%"}
                    />

                    <KeyFigureNew
                      triggerAnimation={inView}
                      delay={1.75}
                      iconName={"nature"}
                      alt="Ikon skog"
                      color={secondaryColor}
                      size={headingSmall}
                      value={calculatePercentage(
                        municipality2.forest,
                        municipality2.total
                      )}
                      subText={"skog"}
                      unit={"%"}
                    />
                    <KeyFigureNew
                      triggerAnimation={inView}
                      delay={1.75}
                      iconName={"spa"}
                      alt="Icon sädesslag"
                      color={secondaryColor}
                      size={headingSmall}
                      value={calculatePercentage(
                        municipality2.agriculture,
                        municipality2.total
                      )}
                      subText={"jordbruksmark"}
                      unit={"%"}
                    />
                    <KeyFigureNew
                      triggerAnimation={inView}
                      delay={1.75}
                      iconName={"stripes"}
                      alt="Ikon ränder"
                      color={secondaryColor}
                      size={headingSmall}
                      value={calculatePercentage(
                        municipality2.other,
                        municipality2.total
                      )}
                      subText={"övrig mark"}
                      unit={"%"}
                    />
                  </SvgLayout>
                </SvgLayout>
              ) : (
                <SvgLayout width="50%">
                  <EmptySpace scroll={scroll} />
                </SvgLayout>
              )}
            </FiguresWrapper>
          </div>
        ) : null}
      </BlockLayout>
    </React.Fragment>
  );
};

export default LandUseContainer;
