import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  messageBoxBackground,
  lineColor,
  primaryColor
} from "./../../Util/Colors";
import { roboto } from "../../Util/StyleProps";
import { device } from "../../Util/Breakpoints";

const MessageBox = styled.div.attrs({
  tabIndex: 0
})`
  font-family: ${roboto};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: ${props => (props.show ? "block" : "none")};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : messageBoxBackground};
  max-width: 690px;
  border: 1px solid ${lineColor};
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 30px; 
  
  & button { 
    background-color: ${props =>
      props.backgroundColor ? props.backgroundColor : messageBoxBackground};
    border: 1px solid ${primaryColor};
    color: ${primaryColor};
    font-weight: 500;
    font-size: 14px;
    line-height: 34px;
    border-radius: 25px;
    margin-top: 12px;
    margin-bottom: 24px;
    padding-left: 18px;
    padding-right: 18px;
  }
  
  & button:hover {
    text-decoration: underline;
  }
  & button:click {
    text-decoration: underline;
    opacity: .82;
  }

  @media${device.tablet} {
    font-size: 18px;
    line-height: 30px;
    padding-left: 30px;
    padding-right: 30px;

    & button { 
      margin-top: 18px;
      margin-bottom: 35px;  
    }
  }
 
  &:focus {
    outline: 1px solid ${lineColor};
  }
`;

const Heading = styled.h3.attrs({
  tabIndex: 0
})`
  padding: 0;
  font-family: ${roboto};
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  margin-top: 24px;
`;

const Message = props => {
  const { show, backgroundColor } = props;
  const messageBoxRef = useRef(null);

  useEffect(() => {
    messageBoxRef.current.focus();
  });
  return (
    <MessageBox
      background={backgroundColor}
      show={show}
      ref={messageBoxRef}
      aria-label="Inforuta angående visa min position"
    >
      <Heading>{props.heading}</Heading>
      <p tabIndex="0">{props.message}</p>
      <button
        onClick={props.handleClickMessageBox}
        aria-label="Stäng informationsruta"
      >
        Stäng
      </button>
    </MessageBox>
  );
};
export default Message;
