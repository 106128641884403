import React from "react";
import styled from "styled-components";
import IconPath from "./../Elements/IconPath";
import { roboto } from "../../Util/StyleProps";
import { primaryColor } from "../../Util/Colors";
import { device } from "../../Util/Breakpoints";

const Wrapper = styled.div`
  display: block;
  width: 100%;
  color: ${props => props.color};
  height: 40px;
`;

const SvgWrapper = styled.div`
  position: relative;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  margin: 0 auto;

  & > svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    padding: 2px;
  }
`;

const Icon = styled.div`
  background: ${primaryColor};
  float: left;
  display: block;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const Link = styled.a`
  display: block;
  padding: 0;
  margin-left: 39px;
  text-decoration: underline;
  color: ${primaryColor};
  font-family: ${roboto};
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  height: 28px;

  @media${device.mobileM} {
    font-size: 16px;
  }

  &:hover {
    text-decoration: underline;
    color: ${primaryColor};
  }
`;

const KeyFigureLink = props => {
  const { children, href, color, iconName, onClickFunction, target } = props;

  return (
    <Wrapper color={color}>
      <Icon>
        <SvgWrapper>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <IconPath color={color} iconName={iconName} />
          </svg>
        </SvgWrapper>
      </Icon>
      <Link
        href={href}
        {...(onClickFunction && {
          onClick: onClickFunction("municipalitySelector")
        })}
        {...(target && { target: target })}
      >
        {children}
      </Link>
    </Wrapper>
  );
};

KeyFigureLink.defaultProps = {
  href: "#",
  iconName: "",
  onClickFunction: null,
  target: null
};

export default KeyFigureLink;
