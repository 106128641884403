import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { Heading1, SubtitleBlock } from "../../Util/StyleProps";
import { createMarkup } from "../../Helpers/CommonHelper";

const Wrapper = styled.div`
  padding: 40px 25px 20px 0px;
`;

const ComponentHeader = props => {
  const { heading, text } = props;
  return (
    <Wrapper>
      <Heading1 tabIndex="0">{heading}</Heading1>
      <SubtitleBlock
        tabIndex="0"
        dangerouslySetInnerHTML={createMarkup(text)}
      ></SubtitleBlock>
    </Wrapper>
  );
};

ComponentHeader.defaultProps = {
  heading: "Heading",
  text: ""
};

export default ComponentHeader;
