import axios from "axios";
import { municipalities } from "../data/kommuner";
import {
  geodetic_to_grid,
  swedish_params
} from "../LatLongConverter/geodesi/gausskruger";
//Find users muncicipality
export async function findUsersMunicipality(latitude, longitude) {
  var lat_lon = convertLatAndLongToSweRef(latitude, longitude);
  return getMunicipalityCode(lat_lon)
    .then(response => {
      if (
        Array.isArray(response.data.features) &&
        response.data.features.length
      ) {
        return findUsersMunicipalityObject(response.data.features[0].id);
      } else {
        throw new UserException();
      }
    })
    .catch(error => {
      throw new UserException();
    });
}

function UserException() {
  var error = {
    message:
      "Vi hittade tyvärr inte din position, du får välja bland kommunerna själv."
  };
  return error;
}
//Return users municipality in top of the list.
export function findUsersMunicipalityObject(id) {
  var municipalitySelector = null;
  municipalitySelector = municipalities();
  var secondElement = municipalitySelector.find(function(item) {
    return item.id == id;
  });
  var your_position_text = " - din position";
  municipalitySelector.splice(municipalitySelector.indexOf(secondElement), 1);
  var your_position = secondElement.name.concat(your_position_text);
  secondElement.name = your_position;
  municipalitySelector.unshift(secondElement);
  return municipalitySelector;
}

//Convert from WGS 84 To SWEREF 99 TM 3006
function convertLatAndLongToSweRef(latitude, longitude) {
  var lat_lon = null;
  swedish_params("sweref_99_tm");
  return (lat_lon = geodetic_to_grid(latitude, longitude));
}
//Retrieves the user's municipality based on geoposition
export async function getMunicipalityCode(lat_lon) {
  var query = {
    geometri: {
      type: "Point",
      crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG::3006" } },
      coordinates: [lat_lon[1], lat_lon[0]]
    },
    buffer: 50
  };
  var token = process.env.REACT_APP_LANTMATERI_TOKEN;
  return axios({
    method: "post",
    data: query,
    url: process.env.REACT_APP_LANTMATERI_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log(error);
    });
}
//Retrieves the user's geoposition from the browser
export function getCurrentPosition(options = {}) {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

// // //Checks whether the user has consented to use his or her position
// export async function checkPermission() {
//   return await navigator.permissions
//     .query({ name: "geolocation" })
//     .then(function(result) {
//       console.log("inside geo permission awaited result");
//       return result.state;
//     });
// }

//Checks whether the user has consented to use his or her position
export async function checkPermission() {
  return (await navigator.permissions.query({ name: "geolocation" })).state;
}
