import React from "react";
import styled from "styled-components";
import { rotate360CW, rotate360CCW } from "../../Util/StyleProps";

const Spinner = props => {
  let Spin = styled.div`
    animation: ${props.counterClockwise ? rotate360CCW : rotate360CW} infinite
      1s linear;
    width: ${props.size ? props.size : "60px"};
    height: ${props.size ? props.size : "60px"};
    margin: 0 auto;
  `;

  return <Spin>{props.children}</Spin>;
};

export default Spinner;
