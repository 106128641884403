import React from "react";
import styled from "styled-components";
import BlockLayout from "../Layout/BlockLayout";
import { sectionPopulationColor } from "./../../Util/Colors";
import {
  Heading1,
  fontSizeMobile,
  lineheightSmall,
  ptSerif,
  fontSizeTablet,
  lineheightMedium
} from "../../Util/StyleProps";
import { device } from "../../Util/Breakpoints";

const Text = styled.p`
  font-size: ${fontSizeMobile};
  font-family: ${ptSerif};
  line-height: ${lineheightSmall};
  max-width:690px;

  @media${device.tablet}{
    font-size: ${fontSizeTablet};
    line-height: ${lineheightMedium};
  }
`;

const Wrapper = styled.div`
  height: auto;
  min-height: 50vh;
  background: ${sectionPopulationColor};
`;
const StartPage = props => {
  return (
    <Wrapper>
      <BlockLayout
        background={sectionPopulationColor}
        paddingBottom="48px"
        paddingTop="36px"
      >
        <>
          <Heading1
            fontSize="20px"
            lineHeight="28px"
            fontSizeTablet="24px"
            lineHeightTablet="34px"
          >
            Snabb koll på Sveriges kommuner
          </Heading1>
          <Text>
            Hur många bor i kommunen? Hur mycket tjänar de? Och vad används
            marken till? Här får du snabbt svar. Du kan titta på hur det ser ut
            i bara en kommun eller jämföra en kommun med en annan.
          </Text>
          <Text>
            Om du har mobil med geopositioneringen påslagen kan du enkelt få upp
            kommunen du befinner dig i genom att välja ”Din position”.
          </Text>
          <Text>Har du koll på hur det ser ut i din kommun?</Text>
        </>
      </BlockLayout>
    </Wrapper>
  );
};

export default StartPage;
