import React from "react";
import MainNav from "./MainNav";
import styled from "styled-components";
import { lineColor } from "./../../Util/Colors";
const HeaderWrapper = styled.header`
  border-bottom: 1px solid ${lineColor};
`;
const Header = () => {
  return (
    <HeaderWrapper>
      <MainNav />
    </HeaderWrapper>
  );
};

export default Header;
