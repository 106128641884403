import React, { useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import styled from "styled-components";
import KeyFigureNew from "../Elements/KeyFigureNew";
import BubbleFlexSvg from "../Elements/BubbleFlexSvg";
import BlockLayout from "./../Layout/BlockLayout";
import SvgLayout from "./../Layout/SvgLayout";
import { createMarkup } from "../../Helpers/CommonHelper";
import {
  headingSmall,
  Heading2,
  Heading6,
  HeadingMunicipality,
  Line,
  FiguresWrapper
} from "../../Util/StyleProps";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import { useInView } from "react-intersection-observer";
import {
  Sign,
  ArrowValue,
  numberWithSpaces,
  isCurrentYear
} from "../../Helpers/CommonHelper";
import HighLineChartLine from "../Charts/HighChartLineChart";
import EmptySpace from "../Elements/EmptySpace";
import FigureBoxPopulation from "./FigureBoxPopulation";
import PopulationChange from "./PopulationChange";


const PopulationContainer = props => {
  const { sweden, data, populationchangedata, blockColor, scroll } = props;
  var xaxissize = "";
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true
  });

  const Wrapper = styled.div`
    .highcharts-background {
      fill: none;
      background-color: #de82dc !important;
    }
  `;

  let year = populationchangedata[0] ? populationchangedata[0].year : "";
  const lastYear = sweden.population.values.slice(-1)[0];
  const beforeYear = sweden.population.values.slice(-2)[0];
  const popDiff = lastYear.population - beforeYear.population;

  let popKingdom = null;

  let municipality1 = data[0];
  let municipality2 = data[1];

  let municipality1Change = populationchangedata[0];
  let municipality2Change = populationchangedata[1];

  const noWrapPopulation =
    "<span class='text-nowrap'>" +
    numberWithSpaces(lastYear.population) +
    "</span>";

  const noWrapDiff =
    "<span class='text-nowrap'>" + numberWithSpaces(popDiff) + "</span>";

  let subText = `I slutet av ${
    lastYear ? lastYear.year : "(årtal saknas)"
  } var Sveriges folkmängd ${
    lastYear ? noWrapPopulation : "(befolkningsmängd ej tillgänglig)"
  } personer.`;

  var subTextKeyFig = "personer";

  // Accessibility
  const ariaLabel = (municipality, year) => {
    return municipality
      ? `I slutet av ${year} var befolkningen i ${
          municipality.name
        } kommun ${municipality.values.slice(-1).pop()} personer`
      : "";
  };

  const ariaLabelPopChange = municipalityChange => {
    return municipalityChange
      ? `Befolkningen i ${municipalityChange.name} kommun ${
          Math.sign(municipalityChange.value) === 0
            ? isCurrentYear(municipalityChange.year)
              ? "är"
              : "var"
            : Math.sign(municipalityChange.value) > 0
            ? "ökade med"
            : "minskade med"
        } ${Math.abs(municipalityChange.value)} personer (år ${
          municipalityChange.year
        })`
      : "";
  };

  return (
    <React.Fragment>
      <BlockLayout background={blockColor}>
        <ComponentHeader heading={"Så många är vi"} text={subText} />
        {municipality1 ? (
          <>
            <FigureBoxPopulation
              municipality1={municipality1}
              municipality2={municipality2}
              year={year}
              scroll={scroll}
            />
            <div ref={ref}>
              {municipality1 ? (
                <SvgLayout
                  paddingBottom="30px"
                  ariaLabel={`Det här linjediagramet visar populationsförändring i ${
                    municipality1.name
                  } kommun ${
                    municipality2 ? "och " + municipality2.name + "kommun" : ""
                  } `}
                  useTabIndex
                >
                  <Wrapper>
                    <HighLineChartLine
                      data={data}
                      startpoint={1970}
                      triggerAnimation={inView}
                    />
                  </Wrapper>
                </SvgLayout>
              ) : (
                ""
              )}
            </div>
            <PopulationChange sweden={sweden} data={data} populationchangedata={populationchangedata}/>
          </>
        ) : null}
      </BlockLayout>
    </React.Fragment>
  );
};

PopulationContainer.defaultProps = {
  data: [],
  sweden: [],
  populationchangedata: []
};

export default PopulationContainer;
