import React from "react";
import styled, { keyframes } from "styled-components";
import BlockLayout from "./../Layout/BlockLayout";
import SvgLayout from "./../Layout/SvgLayout";
import { Row } from "react-bootstrap";
import ComponentHeader from "../Header/ComponentHeader";
import Piechart from "./../Charts/PieChart";
import { useInView } from "react-intersection-observer";
// import { fadeIn, slideInUp } from "react-animations";
import BubbleFlexSvg from "../Elements/BubbleFlexSvg";
import { HeadingMunicipality, HorizontalLine } from "../../Util/StyleProps";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import { valuePairToPercent } from "../../Helpers/CommonHelper";
import EmptySpace from "../Elements/EmptySpace";

// const slideInAnimation = keyframes`${slideInUp}`;
// const fadeInAnimation = keyframes`${fadeIn}`;

const GenderContainer = props => {
  const { data, sweden, blockColor, scroll } = props;

  let valueData = [];
  let swedenArr, swedenString;

  const lastYear = sweden && sweden.gender ? sweden.gender.year : "";
  swedenArr = valuePairToPercent(sweden.gender, ["male", "female"], 1);
  swedenString =
    "I Sverige är " +
    swedenArr.pstring2 +
    " procent kvinnor och " +
    swedenArr.pstring1 +
    " procent män (" +
    lastYear +
    ").";

  data.forEach((item, i) => {
    if (item) {
      valueData.push(valuePairToPercent(item, ["male", "female"], 1));
    }
  });

  let municipality1 = valueData[0];
  let municipality2 = valueData[1];

  // Accessibility
  const ariaLabel = municipality =>
    municipality
      ? `I ${municipality.name} kommun finns det ${municipality.pstring2} procent kvinnor och ${municipality.pstring1} procent män.`
      : "";

  const svgAriaLabel = municipality =>
    municipality
      ? `Det här pajdiagrammet jämför antalet kvinnor och män i ${municipality.name} kommun.`
      : "";

  //Attention values and labels in this component is mixed to match position of pie elements
  return (
    <React.Fragment>
      <BlockLayout background={blockColor} paddingBottom="6px">
        <ComponentHeader
          heading={"Så ser könsfördelningen ut"}
          text={swedenString}
        />
        {municipality1 ? (
          <>
            {municipality1 ? (
              <>
                <HeadingMunicipality
                  aria-label={ariaLabel(municipality1)}
                  color={primaryColor}
                >
                  {municipality1.name}
                </HeadingMunicipality>
                <SvgLayout
                  maxWidth="1000px"
                  center
                  ariaLabel={svgAriaLabel(municipality1)}
                  useTabIndex
                >
                  <GenderBlock
                    blockColor={blockColor}
                    ariaId={"GenderBlock1"}
                    title={`Pajdiagram för ${municipality1.name} kommun`}
                    desc={svgAriaLabel(municipality1)}
                    color={primaryColor}
                    data={[
                      {
                        value: municipality1.value1,
                        color: "transparent",
                        background: "stripesBlue",
                        pstring: municipality1.pstring2
                      },
                      {
                        value: municipality1.value2,
                        color: primaryColor,
                        pstring: municipality1.pstring1
                      }
                    ]}
                  />
                </SvgLayout>
              </>
            ) : (
              <div></div>
            )}
            <HorizontalLine marginBottom="18px" />
            {municipality2 ? (
              <>
                <HeadingMunicipality
                  aria-label={ariaLabel(municipality2)}
                  color={secondaryColor}
                >
                  {municipality2.name}
                </HeadingMunicipality>
                <SvgLayout
                  maxWidth="1000px"
                  center
                  ariaLabel={svgAriaLabel(municipality2)}
                  useTabIndex
                >
                  <GenderBlock
                    blockColor={blockColor}
                    ariaId={"GenderBlock2"}
                    title={`Pajdiagram för ${municipality2.name} kommun`}
                    color={secondaryColor}
                    desc={svgAriaLabel(municipality2)}
                    data={[
                      {
                        value: municipality2.value1,
                        color: "transparent",
                        background: "stripes",
                        pstring: municipality2.pstring2
                      },
                      {
                        value: municipality2.value2,
                        color: secondaryColor,
                        pstring: municipality2.pstring1
                      }
                    ]}
                  />
                </SvgLayout>
              </>
            ) : (
              <SvgLayout>
                <EmptySpace scroll={scroll} />
              </SvgLayout>
            )}
          </>
        ) : null}
      </BlockLayout>
    </React.Fragment>
  );
};

const GenderBlock = props => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true
  });

  const { data, blockColor, desc, title, ariaId, color } = props;
  const maxIsFemale = data[0].value < data[1].value;

  return (
    <React.Fragment>
      <div ref={ref}>
        <Row>
          <div style={{ width: "35%" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={"100%"}
              height={"100%"}
              viewBox={`0 0 100 120`}
            >
              <BubbleFlexSvg
                triggerAnimation={inView}
                value={data[0].pstring}
                lineType={"curved-left"}
                label={"kvinnor"}
                x={10}
                hasBackground={maxIsFemale}
                delay={0.75}
                color={color}
                isPercent
              />
            </svg>
          </div>
          <div
            style={{
              width: "30%",
              textAlign: "center",
              paddingTop: "90px",
              paddingBottom: "25px"
            }}
          >
            <Piechart
              blockColor={blockColor}
              desc={desc}
              title={title}
              ariaId={ariaId}
              triggerAnimation={inView}
              width={100}
              bgRotation={"-45"}
              data={data}
            />
          </div>
          <div style={{ width: "35%" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={"100%"}
              height={"100%"}
              viewBox={`0 0 100 120`}
            >
              <BubbleFlexSvg
                triggerAnimation={inView}
                value={data[1].pstring}
                lineType={"curved-right"}
                label={"män"}
                x={10}
                hasBackground={!maxIsFemale}
                delay={0.75}
                color={color}
                isPercent
              />
            </svg>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

GenderContainer.defaultProps = {
  data: [],
  sweden: {}
};

export default GenderContainer;
