//CommonHelper.js
//Format number to 1 digit

export function formatNumber(x) {
  return Number.parseFloat(x).toFixed(1);
}

export function calculatePercentage(partialValue, total, dec = 1) {
  return parseFloat((100 * Number(partialValue)) / Number(total)).toFixed(dec);
}
//Calculate averageage
export function calculateAverageAge(n1, n2) {
  var age = (Number(n1) + Number(n2)) / 2;
  return age ? age.toFixed(1) : 0;
}
//Format number with spa
export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function Sign(value) {
  const pop = value;
  if (pop > 0) {
    return "+" + numberWithSpaces(value);
  }
  return value;
}
export function isCurrentYear(year) {
  var date = new Date();
  return Number(year) == Number(date.getFullYear());
}

export function ArrowValue(value) {
  const pop = Math.sign(value);
  if (pop == 0) {
    return "arrow-forward";
  } else if (pop > 0) {
    return "arrow-upward";
  }
  return "arrow-downward";
}

export function checkForValues(value) {
  return value.includes("..") ? false : true;
}

export function isNullorEmpty(arr) {
  if (arr) {
    if (!Array.isArray(arr) && arr.length == 0) return true;

    // .some returns a boolean
    let hasValue = arr.some(function(value) {
      return value !== null;
    });

    return hasValue ? false : true;
  } else {
    return false;
  }
}

export function createMarkup(text) {
  return { __html: text };
}

export const periodToComma = num => num.toString().replace(/[.]/, ",");

export const valuePairToPercent = (obj, keys, dec) => {
  let total = parseInt(obj[keys[0]], 10) + parseInt(obj[keys[1]], 10);
  let value1 = 0;
  let value2 = 0;

  value1 = parseFloat((100 * parseInt(obj[keys[0]])) / total);
  value2 = parseFloat((100 * parseInt(obj[keys[1]])) / total);

  return {
    ...{
      value1: value1,
      value2: value2,
      pstring1: periodToComma(value1.toFixed(dec)),
      pstring2: periodToComma(value2.toFixed(dec))
    },
    ...obj
  };
};

export const GetUniqueLocations = rawData => {
  let locations = [];
  rawData.forEach(element => {
    if (locations.indexOf(element.key[0]) === -1) {
      locations.push(element.key[0]);
    }
  });
  return locations;
};

export function getBrowserQueryParams() {
  var qd = { id1: null, id2: null };
  if (window.location.search)
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function(item) {
        var s = item.split("="),
          k = s[0],
          v = s[1] && decodeURIComponent(s[1]);
        (qd[k] = qd[k] || []).push(v);
      });
  return qd;
}
