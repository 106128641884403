import React, { Component } from "react";
import "./Styles/Dist/Bootstrap/css/bootstrap.min.css";
import "./Styles/Dist/fonts/Druk/druk.css";
import "./Styles/Dist/fonts/Roboto/roboto.css";
import "./Styles/Dist/fonts/PT_Serif/PT_serif.css";
import { Api2 } from "./Components";

import axios from "axios";

import PageLayout from "./Components/Layout/PageLayout";

import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill for smoothscroll!
smoothscroll.polyfill();

const FILTER_POP_KINGDOM = "vs:RegionRiket99";
const FILTER_EjAggr = "EjAggr";
const FILTER_BARAEjAggr = "+BaraEjAggr";
const FILTER_MUNICIPALITY = "vs:RegionKommun07";
const SWEDEN_CODE = "00";

export class App2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestedMunicipalities: Api2.getRequestedMunicipalities(),
      landusedata: [],
      populationLocationdata: [],
      all_population_change_data: [],
      populationchangedata: [],
      populationdata: [],
      agedata: [],
      municipalityselection: [],
      montelyincomedata: [],
      sweden: {},
      landusedata: [],
      accommodationdata: [],
      genderdata: [],
      genderAgeData: [],
      birthplacedata: [],
      emplomentextentdata: [],
      livingconditiondata: [],
      taxratedata: [],
      electiondata: [],
      isLoadingData: false
    };
  }
  /* 13 anrop*/
  componentDidMount() {
    axios
      .all([
        Api2.getPopulation(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getPopulationLocation(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getPopulationChange(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getAccommodation(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getGender(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getGenderAge(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getIncome(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getLandUse(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getBirthPlace(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getEmplomentExtent(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getLivingCondition(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getTaxRate(FILTER_POP_KINGDOM, SWEDEN_CODE),
        Api2.getElection(FILTER_POP_KINGDOM, SWEDEN_CODE)
      ])
      .then(
        axios.spread(
          (
            pop,
            popLoc,
            popChange,
            acc,
            gender,
            genderAge,
            income,
            landuse,
            birthplace,
            emplomentextent,
            livingcondition,
            taxrate,
            election
          ) => {
            this.setState({
              sweden: {
                ...this.state.sweden,
                population: pop[0],
                populationLocation: popLoc[0],
                populationChange: popChange[0],
                accommodation: acc[0],
                gender: gender[0],
                age: genderAge[0],
                montelyincome: income[0],
                landuse: landuse[0],
                birthplace: birthplace[0],
                emplomentextent: emplomentextent[0],
                livingcondition: livingcondition[0],
                taxrate: taxrate[0],
                election: election[0]
              }
            });
          }
        )
      );
  }

  datahandler = municipalities => {
    this.setState({ isLoadingData: true });
    if (municipalities.length > 1) {
      var municipalities1 =
        municipalities[0] != null ? municipalities[0] : null;
      var municipalities2 =
        municipalities[1] != null ? municipalities[1] : null;
      axios
        .all([
          Api2.getGender(FILTER_MUNICIPALITY, municipalities1, municipalities2),
          Api2.getPopulationLocation(
            FILTER_MUNICIPALITY,
            municipalities1,
            municipalities2
          ),
          Api2.getGenderAge(
            FILTER_MUNICIPALITY + FILTER_EjAggr,
            municipalities1,
            municipalities2
          ),
          Api2.getPopulation(
            FILTER_MUNICIPALITY,
            municipalities1,
            municipalities2
          ),
          Api2.getPopulationChange(
            FILTER_MUNICIPALITY,
            municipalities1,
            municipalities2
          ),
          Api2.getBirthPlace(
            FILTER_MUNICIPALITY,
            municipalities1,
            municipalities2
          ),
          Api2.getLandUse(
            FILTER_MUNICIPALITY,
            municipalities1,
            municipalities2
          ),
          Api2.getIncome(
            FILTER_MUNICIPALITY + FILTER_EjAggr,
            municipalities1,
            municipalities2
          ),
          Api2.getLivingCondition(
            FILTER_MUNICIPALITY,
            municipalities1,
            municipalities2
          ),
          Api2.getAccommodation(
            FILTER_MUNICIPALITY + FILTER_EjAggr,
            municipalities1,
            municipalities2
          ),
          Api2.getTaxRate(
            FILTER_MUNICIPALITY + FILTER_EjAggr,
            municipalities1,
            municipalities2
          ),
          Api2.getElection(
            FILTER_MUNICIPALITY + FILTER_BARAEjAggr,
            municipalities1,
            municipalities2
          ),
          Api2.getEmplomentExtent(
            FILTER_MUNICIPALITY,
            municipalities1,
            municipalities2
          )
        ])
        .then(
          axios.spread(
            (
              gender,
              populationLocation,
              genderAge,
              population,
              populationChange,
              birthplace,
              landuse,
              montelyincome,
              livingcondition,
              accommodation,
              taxrate,
              election,
              emplomentextent
            ) => {
              this.setState({
                genderdata: gender,
                populationLocationdata: populationLocation,
                genderAgeData: genderAge,
                populationdata: population,
                populationchangedata: populationChange,
                birthplacedata: birthplace,
                landusedata: landuse,
                montelyincomedata: montelyincome,
                livingconditiondata: livingcondition,
                taxratedata: taxrate,
                electiondata: election,
                accommodationdata: accommodation,
                emplomentextentdata: emplomentextent,
                isLoadingData: false
              });
            }
          )
        );
    } else {
      console.log("en eller ingen");
    }
  };

  render() {
    return (
      <React.Fragment>
        <PageLayout data={this.state} datahandler={this.datahandler} />
      </React.Fragment>
    );
  }
}

export default App2;
