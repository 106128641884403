import React from "react";
import styled, { keyframes } from "styled-components";
import { Col, Row, Figure } from "react-bootstrap";
import { fadeIn, slideInUp } from "react-animations";
import { useInView } from "react-intersection-observer";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Image } from "react-bootstrap";

import {
  drukText,
  letterSpacingDruk,
  fontSizeSmall,
  drawLine,
  scaleInBottomBubbleDouble
} from "../../Util/StyleProps";
import { secondaryColor } from "../../Util/Colors";

const slideInAnimation = keyframes`${slideInUp}`;
const fadeInAnimation = keyframes`${fadeIn}`;

const Wrapper = styled.div`
  margin-top: 10px;
`;

const Svg = styled.svg`
  margin-top: 30px;
`;

const G = styled.g``;
const Heading = styled.h6`
  font-size: 12px;
  font-family: Roboto;
  margin-left: 30px;
  font-weight: bold;
`;

const Bubble = styled.rect`
  -webkit-animation: 0.5s
    ${props => (props.triggeranimation ? scaleInBottomBubbleDouble : "")}
    cubic-bezier(0.175, 0.885, 0.275, 1.235);
  animation: 0.5s
    ${props => (props.triggeranimation ? scaleInBottomBubbleDouble : "")}
    cubic-bezier(0.175, 0.885, 0.275, 1.235);
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
  fill: #ffffff;
  width: 90%;
  height: 60px;
`;
const Value = styled.text`
  fill: ${props => props.color};
  font-family: ${drukText};
  letter-spacing: ${letterSpacingDruk};
  font-size: 35px;
  line-height: 33px;
  animation: 0.25s ${props => (props.triggeranimation ? fadeInAnimation : "")};
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
`;

const ValueLine = styled.path`
  stroke: ${secondaryColor};
  stroke-width: 1;
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
  animation: ${props => (props.triggeranimation ? drawLine : drawLine)} 0.25s;
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
`;

const ValueText = styled.text`
  fill: ${props => props.color};
  font-family: ${drukText};
  letter-spacing: ${letterSpacingDruk};
  font-size: ${fontSizeSmall};
  animation: 0.1s ${props => (props.triggeranimation ? fadeInAnimation : "")};
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
`;
const Text = styled.text``;

const SurfaceArea = props => {
  return (
    <Col item xs={9} sm={9} md={2} lg={6}>
      <Wrapper color={props.color}>
        <Svg width={100} viewBox="0 0 100 100">
          <G>
            <Bubble
              triggeranimation={props.triggeranimation}
              delay={props.delay + 0.25}
              x={0}
              y={0}
              rx="25"
            />
            <Value
              triggeranimation={props.triggeranimation}
              color={props.color}
              delay={props.delay + 0.85}
              y={40}
              x={15}
            >
              {props.value}
            </Value>
            <ValueText
              triggeranimation={props.triggeranimation}
              delay={props.delay + 0.85}
              color={props.color}
              x={35}
              y={50}
            >
              km2
            </ValueText>
          </G>
        </Svg>
      </Wrapper>
    </Col>
  );
};
export default SurfaceArea;
