import React from "react";
import stripes from "../../assets/img/stripes.svg";
import stripesBlue from "../../assets/img/stripesBlue.svg";

const patternElements = {
  stripes: stripes,
  stripesBlue: stripesBlue

};

const Pattern = props => {
  const { width, rotation, patternId } = props;

  return (
    <pattern
      id={patternId}
      patternUnits="userSpaceOnUse"
      width={width}
      height={width}
    >
      <image
        xlinkHref={patternElements[patternId]}
        x="0"
        y="0"
        width={width}
        height={width}
        transform={`rotate(${rotation} ${width / 2} ${width / 2}) scale(3, 3)`}
      />
    </pattern>
  );
};

export default Pattern;
