import React from "react";
import styled from "styled-components";
import { sectionPopulationColor } from "./../../Util/Colors";
import { maxWidthBlock } from "./../../Util/StyleProps";
import { Container } from "react-bootstrap";
import { device } from "./../../Util/Breakpoints";

const Wrapper = styled.div`
  margin:0 auto !important;
  padding:0 24px;

  @media${device.mobileL}{
    padding: 0 36px;
  }
  @media${device.tablet}{
    max-width: ${props => (props.maxWidth ? props.maxWidth : "100%")};
    padding: 0 48px;
  }
`;

const BlockLayout = props => {
  const {
    children,
    background,
    paddingTop,
    paddingBottom,
    paddingRight,
    paddingLeft,
    maxWidth,
    height
  } = props;
  return (
    <Container
      fluid
      style={{
        background: background,
        paddingTop,
        paddingBottom,
        paddingRight,
        paddingLeft,
        height
      }}
    >
      <Wrapper maxWidth={maxWidth}>{children}</Wrapper>
    </Container>
  );
};

BlockLayout.defaultProps = {
  background: { sectionPopulationColor },
  maxWidth: maxWidthBlock,
  paddingTop: "0",
  paddingBottom: "50px",
  paddingLeft: "0",
  paddingRight: "0",
  height: "auto"
};

export default BlockLayout;
