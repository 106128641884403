import React from "react";
import BlockLayout from "./../Layout/BlockLayout";
import SvgLayout from "./../Layout/SvgLayout";
import ComponentHeader from "../Header/ComponentHeader";
import DoubleBarSvg from "../Charts/DoubleBarSvg";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import {
  Line,
  FiguresWrapper,
  HeadingMunicipality
} from "../../Util/StyleProps";
import EmptySpace from "../Elements/EmptySpace";
import { valuePairToPercent, isCurrentYear } from "../../Helpers/CommonHelper";
import { useInView } from "react-intersection-observer";

const BirthPlaceContainer = props => {
  const { data, sweden, blockColor, scroll } = props;
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });
  let valueData = [];
  let valueArr = [];
  let swedenArr, swedenString;
  const lastYear = sweden.birthplace.year;

  swedenArr = valuePairToPercent(
    sweden.birthplace,
    ["borninsweden", "bornabroud"],
    1
  );
  swedenString = `Av hela Sveriges befolkning är ${swedenArr.pstring1} procent födda i Sverige och ${swedenArr.pstring2} procent födda utomlands (${lastYear}).`;

  data.forEach((item, i) => {
    if (item) {
      valueData.push(
        valuePairToPercent(item, ["borninsweden", "bornabroud"], 1)
      );
      valueArr.push(valueData[i].value1);
      valueArr.push(valueData[i].value2);
    }
  });

  let municipality1 = valueData[0];
  let municipality2 = valueData[1];
  const max = Math.max(...valueArr);
  const maxColumnPairIdx = valueArr.indexOf(Math.max(...valueArr));
  const maxIsEven = maxColumnPairIdx % 2 == 0;

  const maxOffset = max;
  const height = 120;
  const width = 100;
  const topSpace = 63;

  let captions = ["födda i Sverige", "födda utomlands"];

  // Accessibility
  const ariaLabel = municipality => {
    const str = municipality
      ? isCurrentYear(municipality.year)
        ? "är"
        : "var"
      : "";

    return municipality
      ? `I ${municipality.name} kommun så ${str} ${municipality.value1} procent av befolkningen födda i Sverige och ${municipality.value2} procent födda utomlands. (år ${municipality.year})`
      : "";
  };

  const svgAriaLabel = municipality =>
    municipality
      ? `Det här stapeldiagramet gör jämförelser i procent för antal personer födda i Sverge och utomlands. För ${municipality.name} kommun.`
      : "";

  return (
    <React.Fragment>
      <BlockLayout background={blockColor}>
        <ComponentHeader heading={"Här är vi födda"} text={swedenString} />
        {municipality1 ? (
          <div ref={ref}>
            <FiguresWrapper maxWidth="1000px">
              {municipality1 ? (
                <SvgLayout width="50%">
                  <HeadingMunicipality
                    aria-label={ariaLabel(municipality1)}
                    color={primaryColor}
                  >
                    {municipality1.name}
                  </HeadingMunicipality>
                  <SvgLayout
                    ariaLabel={svgAriaLabel(municipality1)}
                    useTabIndex
                  >
                    <DoubleBarSvg
                      ariaId={"BirthPlace1"}
                      triggerAnimation={inView}
                      idx={0}
                      width={width}
                      height={height}
                      topSpace={topSpace}
                      delay={0.75}
                      maxOffset={maxOffset}
                      maxIsEven={maxIsEven}
                      firstValue={municipality1.value1}
                      secondValue={municipality1.value2}
                      pstring1={municipality1.pstring1}
                      pstring2={municipality1.pstring2}
                      captions={captions}
                      color={primaryColor}
                      index={[0, 1]}
                      title={municipality1.name}
                      desc={svgAriaLabel(municipality1)}
                      hasLine
                    />
                  </SvgLayout>
                </SvgLayout>
              ) : (
                <div> </div>
              )}
              <Line marginBottom="36px" />
              {municipality2 ? (
                <SvgLayout width="50%" marginLeft="30px">
                  <HeadingMunicipality
                    aria-label={ariaLabel(municipality2)}
                    color={secondaryColor}
                  >
                    {municipality2.name}
                  </HeadingMunicipality>
                  <SvgLayout
                    ariaLabel={svgAriaLabel(municipality2)}
                    useTabIndex
                  >
                    <DoubleBarSvg
                      idx={1}
                      ariaId={"BirthPlace2"}
                      width={width}
                      height={height}
                      topSpace={topSpace}
                      triggerAnimation={inView}
                      delay={1.75}
                      maxOffset={maxOffset}
                      maxIsEven={maxIsEven}
                      firstValue={municipality2.value1}
                      secondValue={municipality2.value2}
                      pstring1={municipality2.pstring1}
                      pstring2={municipality2.pstring2}
                      captions={captions}
                      color={secondaryColor}
                      index={[2, 3]}
                      title={municipality2.name}
                      desc={svgAriaLabel(municipality2)}
                    />
                  </SvgLayout>
                </SvgLayout>
              ) : (
                <SvgLayout width="50%">
                  <EmptySpace scroll={scroll} />
                </SvgLayout>
              )}
            </FiguresWrapper>
          </div>
        ) : null}
      </BlockLayout>
    </React.Fragment>
  );
};

BirthPlaceContainer.defaultProps = {
  data: [],
  sweden: {}
};

export default BirthPlaceContainer;
