import React from "react";
import styled from "styled-components";
import BlockLayout from "./../Layout/BlockLayout";
import logoSvg from "../../assets/img/scb_logo_vit.svg";
import logoPng from "../../assets/img/scb_logo_vit.png";
import { roboto, fontSizeSmall } from "./../../Util/StyleProps";
import { black, lineColor } from "./../../Util/Colors";

const FooterWrapper = styled.footer`
  border-bottom: 1px solid ${lineColor};
  background: ${black};
  width: 100%;
  min-height: 144px;
  padding-bottom: 30px;
`;
const Text = styled.p`
  color: #fff;
  font-family: ${roboto};
  font-size: ${fontSizeSmall};
  line-height: 18px;
  margin: 0 auto;
`;

const Navigation = styled.nav`
  max-width: 327px;
  margin: 0 auto;
  text-align: center;
`;

const Link = styled.a`
  margin: 0 auto;
  text-align: center;
  display: block;
  padding: 24px 0 16px 0;
`;

const Logo = styled.img`
  width: 36px;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <BlockLayout paddingTop="0" paddingBottom="0">
        <Navigation>
          <Link href="http://www.scb.se" aria-label="Till scb.se">
            <Logo src={logoSvg} alt="Statistika Centralbyrån" />
          </Link>
          <Text>
            Statistikmyndigheten SCB förser samhället med statistik för
            beslutsfattande, debatt och forskning.
          </Text>
        </Navigation>
      </BlockLayout>
    </FooterWrapper>
  );
};

export default Footer;
