import React from "react";
import BlockLayout from "../Layout/BlockLayout";
import SvgLayout from "../Layout/SvgLayout";
import ComponentHeader from "../Header/ComponentHeader";
import SingleBarSvgLargeBubble from "../Charts/SingleBarSvgLargeBubble";
import {
  FiguresWrapper,
  Line,
  HeadingMunicipality
} from "../../Util/StyleProps";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import { useInView } from "react-intersection-observer";
import {
  calculatePercentage,
  isCurrentYear,
  numberWithSpaces
} from "../../Helpers/CommonHelper";
import EmptySpace from "../Elements/EmptySpace";

const AccommodationContainer = props => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true
  });

  const { data, sweden, blockColor, scroll } = props;

  let municipality1 = data[0];
  let municipality2 = data[1];

  const lastYear = sweden.accommodation
    ? sweden.accommodation.year
    : "årtal ej tillgängligt";

  let swedenString = sweden
    ? `Genomsnittspriset för sålda småhus i Sverige är 
      ${numberWithSpaces(
        sweden.accommodation.averagePrice
      )} tusen kronor (${lastYear}).`
    : "";

  let total = 100;
  if (municipality1 && municipality2) {
    total =
      Number(municipality1.averagePrice) + Number(municipality2.averagePrice);
  } else if (municipality1) {
    total = municipality1.averagePrice;
  } else if (municipality2) {
    total = municipality2.averagePrice;
  }

  let maxOffset =
    municipality1 && municipality2
      ? 100 -
        calculatePercentage(
          Math.min(
            Number(municipality1.averagePrice),
            Number(municipality2.averagePrice)
          ),
          total
        )
      : 100;

  const height = 150;
  const width = 100;

  const ariaLabel = municipality => {
    const str = municipality
      ? isCurrentYear(municipality.year)
        ? "är"
        : "var"
      : "";

    return municipality
      ? `Genomsnittspriset för sålda småhus i ${municipality.name} kommun ${str} ${municipality.averagePrice} tusen kronor (år ${municipality.year})`
      : "";
  };

  const svgAriaLabel = municipality =>
    municipality
      ? `Det här stapeldiagramet visar genomsnittliga priset i tusen kronor per sålda småhus i ${municipality.name} kommun.`
      : "";

  return (
    <React.Fragment>
      <BlockLayout background={blockColor}>
        <ComponentHeader
          heading={"Så mycket kostar våra hus"}
          text={swedenString}
        />
        {municipality1 ? (
          <div ref={ref}>
            <FiguresWrapper maxWidth="800px">
              {municipality1 ? (
                <SvgLayout width="50%" paddingLeft="0">
                  <HeadingMunicipality
                    color={primaryColor}
                    aria-label={ariaLabel(municipality1)}
                  >
                    {municipality1.name}
                  </HeadingMunicipality>

                  <SvgLayout
                    ariaLabel={svgAriaLabel(municipality1)}
                    useTabIndex
                    paddingTop="10px"
                    center
                    marginLeft="-20px;"
                  >
                    <SingleBarSvgLargeBubble
                      viewBoxHeight={height}
                      viewBoxWidth={width}
                      caption={municipality1.amount + " sålda hus"}
                      lineType={"strait"}
                      valuePercentage={calculatePercentage(
                        municipality1.averagePrice,
                        total
                      )}
                      valueString={municipality1.averagePrice}
                      delay={0.75}
                      triggerAnimation={inView}
                      maxOffset={maxOffset}
                      color={primaryColor}
                      label="tusen kronor"
                    />
                  </SvgLayout>
                </SvgLayout>
              ) : (
                <div> </div>
              )}

              <Line marginBottom="36px" />
              {municipality2 ? (
                <SvgLayout width="50%" marginLeft="30px">
                  <HeadingMunicipality
                    color={secondaryColor}
                    aria-label={ariaLabel(municipality2)}
                  >
                    {municipality2.name}
                  </HeadingMunicipality>

                  <SvgLayout
                    ariaLabel={svgAriaLabel(municipality2)}
                    useTabIndex
                    paddingTop="10px"
                    center
                    marginLeft="-20px;"
                  >
                    <SingleBarSvgLargeBubble
                      viewBoxHeight={height}
                      viewBoxWidth={width}
                      caption={municipality2.amount + " sålda hus"}
                      lineType={"strait"}
                      valuePercentage={calculatePercentage(
                        municipality2.averagePrice,
                        total
                      )}
                      valueString={municipality2.averagePrice}
                      delay={0.75}
                      triggerAnimation={inView}
                      maxOffset={maxOffset}
                      color={secondaryColor}
                      label="tusen kronor"
                    />
                  </SvgLayout>
                </SvgLayout>
              ) : (
                <SvgLayout width="50%">
                  <EmptySpace scroll={scroll} />
                </SvgLayout>
              )}
            </FiguresWrapper>
          </div>
        ) : null}
      </BlockLayout>
    </React.Fragment>
  );
};

AccommodationContainer.defaultProps = {
  data: []
};

export default AccommodationContainer;
