export function getMunicipalityName(id) {
  if (id == "00") return "Sverige";
  return id ? municipalities().find(m => m.id === id).name : null;
}

export function hasMunicipalityName(id) {
  return id ? municipalities().find(m => m.id === id) : null;
}

export function municipalities() {
  var municipalityCollection = [
    /*{ A - 10 st}*/
    { id: "1440", name: "Ale" },
    { id: "1489", name: "Alingsås" },
    { id: "0764", name: "Alvesta" },
    { id: "0604", name: "Aneby" },
    { id: "1984", name: "Arboga" },
    { id: "2506", name: "Arjeplog" },
    { id: "2505", name: "Arvidsjaur" },
    { id: "1784", name: "Arvika" },
    { id: "1882", name: "Askersund" },
    { id: "2084", name: "Avesta" },
    /*{ B - 16 st }*/
    { id: "1460", name: "Bengtsfors" },
    { id: "2326", name: "Berg" },
    { id: "2403", name: "Bjurholm" },
    { id: "1260", name: "Bjuv" },
    { id: "2582", name: "Boden" },
    { id: "1443", name: "Bollebygd" },
    { id: "2183", name: "Bollnäs" },
    { id: "0885", name: "Borgholm" },
    { id: "2081", name: "Borlänge" },
    { id: "1490", name: "Borås" },
    { id: "0127", name: "Botkyrka" },
    { id: "0560", name: "Boxholm" },
    { id: "1272", name: "Bromölla" },
    { id: "2305", name: "Bräcke" },
    { id: "1231", name: "Burlöv" },
    { id: "1278", name: "Båstad" },
    /*{ D - 4 st }*/
    { id: "1438", name: "Dals-Ed" },
    { id: "0162", name: "Danderyd" },
    { id: "1862", name: "Degerfors" },
    { id: "2425", name: "Dorotea" },
    /*{ E - 8 st}*/
    { id: "1730", name: "Eda" },
    { id: "0125", name: "Ekerö" },
    { id: "0686", name: "Eksjö" },
    { id: "0862", name: "Emmaboda" },
    { id: "0381", name: "Enköping" },
    { id: "0484", name: "Eskilstuna" },
    { id: "1285", name: "Eslöv" },
    { id: "1445", name: "Essunga" },
    /*{ F - 9 st}*/
    { id: "1982", name: "Fagersta" },
    { id: "1382", name: "Falkenberg" },
    { id: "1499", name: "Falköping" },
    { id: "2080", name: "Falun" },
    { id: "1782", name: "Filipstad" },
    { id: "0562", name: "Finspång" },
    { id: "0482", name: "Flen" },
    { id: "1763", name: "Forshaga" },
    { id: "1439", name: "Färgelanda" },
    /*{ G - 12 st}*/
    { id: "2026", name: "Gagnef" },
    { id: "0662", name: "Gislaved" },
    { id: "0461", name: "Gnesta" },
    { id: "0617", name: "Gnosjö" },
    { id: "0980", name: "Gotland" },
    { id: "1764", name: "Grums" },
    { id: "1444", name: "Grästorp" },
    { id: "1447", name: "Gullspång" },
    { id: "2523", name: "Gällivare" },
    { id: "2180", name: "Gävle" },
    { id: "1480", name: "Göteborg" },
    { id: "1471", name: "Götene" },
    /*{ H - 28 st}*/
    { id: "0643", name: "Habo" },
    { id: "1783", name: "Hagfors" },
    { id: "1861", name: "Hallsberg" },
    { id: "1961", name: "Hallstahammar" },
    { id: "1380", name: "Halmstad" },
    { id: "1761", name: "Hammarö" },
    { id: "0136", name: "Haninge" },
    { id: "2583", name: "Haparanda" },
    { id: "0331", name: "Heby" },
    { id: "2083", name: "Hedemora" },
    { id: "1283", name: "Helsingborg" },
    { id: "1466", name: "Herrljunga" },
    { id: "1497", name: "Hjo" },
    { id: "2104", name: "Hofors" },
    { id: "0126", name: "Huddinge" },
    { id: "2184", name: "Hudiksvall" },
    { id: "0860", name: "Hultsfred" },
    { id: "1315", name: "Hylte" },
    { id: "0305", name: "Håbo" },
    { id: "1863", name: "Hällefors" },
    { id: "2361", name: "Härjedalen" },
    { id: "2280", name: "Härnösand" },
    { id: "1401", name: "Härryda" },
    { id: "1293", name: "Hässleholm" },
    { id: "1284", name: "Höganäs" },
    { id: "0821", name: "Högsby" },
    { id: "1266", name: "Hörby" },
    { id: "1267", name: "Höör" },
    /*{ J - 8 st}*/
    { id: "2510", name: "Jokkmokk" },
    { id: "0123", name: "Järfälla" },
    { id: "0680", name: "Jönköping" },
    /*{ K - 23 st}*/
    { id: "2514", name: "Kalix" },
    { id: "0880", name: "Kalmar" },
    { id: "1446", name: "Karlsborg" },
    { id: "1082", name: "Karlshamn" },
    { id: "1883", name: "Karlskoga" },
    { id: "1080", name: "Karlskrona" },
    { id: "1780", name: "Karlstad" },
    { id: "0483", name: "Katrineholm" },
    { id: "1715", name: "Kil" },
    { id: "0513", name: "Kinda" },
    { id: "2584", name: "Kiruna" },
    { id: "1276", name: "Klippan" },
    { id: "0330", name: "Knivsta" },
    { id: "2282", name: "Kramfors" },
    { id: "1290", name: "Kristianstad" },
    { id: "1781", name: "Kristinehamn" },
    { id: "2309", name: "Krokom" },
    { id: "1881", name: "Kumla" },
    { id: "1384", name: "Kungsbacka" },
    { id: "1960", name: "Kungsör" },
    { id: "1482", name: "Kungälv" },
    { id: "1261", name: "Kävlinge" },
    { id: "1983", name: "Köping" },
    /*{ L - 21 st}*/
    { id: "1381", name: "Laholm" },
    { id: "1282", name: "Landskrona" },
    { id: "1860", name: "Laxå" },
    { id: "1814", name: "Lekeberg" },
    { id: "2029", name: "Leksand" },
    { id: "1441", name: "Lerum" },
    { id: "0761", name: "Lessebo" },
    { id: "0186", name: "Lidingö" },
    { id: "1494", name: "Lidköping" },
    { id: "1462", name: "Lilla Edet" },
    { id: "1885", name: "Lindesberg" },
    { id: "0580", name: "Linköping" },
    { id: "0781", name: "Ljungby" },
    { id: "2161", name: "Ljusdal" },
    { id: "1864", name: "Ljusnarsberg" },
    { id: "1262", name: "Lomma" },
    { id: "2085", name: "Ludvika" },
    { id: "2580", name: "Luleå" },
    { id: "1281", name: "Lund" },
    { id: "2481", name: "Lycksele" },
    { id: "1484", name: "Lysekil" },
    /*{ M - 16 st}*/
    { id: "1280", name: "Malmö" },
    { id: "2023", name: "Malung-Sälen" },
    { id: "2418", name: "Malå" },
    { id: "1493", name: "Mariestad" },
    { id: "1463", name: "Mark" },
    { id: "0767", name: "Markaryd" },
    { id: "1461", name: "Mellerud" },
    { id: "0586", name: "Mjölby" },
    { id: "2062", name: "Mora" },
    { id: "0583", name: "Motala" },
    { id: "0642", name: "Mullsjö" },
    { id: "1430", name: "Munkedal" },
    { id: "1762", name: "Munkfors" },
    { id: "1481", name: "Mölndal" },
    { id: "0861", name: "Mönsterås" },
    { id: "0840", name: "Mörbylånga" },
    /*{ N - 13 st}*/
    { id: "0182", name: "Nacka" },
    { id: "1884", name: "Nora" },
    { id: "1962", name: "Norberg" },
    { id: "2132", name: "Nordanstig" },
    { id: "2401", name: "Nordmaling" },
    { id: "0581", name: "Norrköping" },
    { id: "0188", name: "Norrtälje" },
    { id: "2417", name: "Norsjö" },
    { id: "0881", name: "Nybro" },
    { id: "0140", name: "Nykvarn" },
    { id: "0480", name: "Nyköping" },
    { id: "0192", name: "Nynäshamn" },
    { id: "0682", name: "Nässjö" },
    /*{ O - 8 st}*/
    { id: "2101", name: "Ockelbo" },
    { id: "1060", name: "Olofström" },
    { id: "2034", name: "Orsa" },
    { id: "1421", name: "Orust" },
    { id: "1273", name: "Osby" },
    { id: "0882", name: "Oskarshamn" },
    { id: "2121", name: "Ovanåker" },
    { id: "0481", name: "Oxelösund" },
    /*{ P - 4 st}*/
    { id: "2521", name: "Pajala" },
    { id: "1402", name: "Partille" },
    { id: "1275", name: "Perstorp" },
    { id: "2581", name: "Piteå" },
    /*{ R - 4 st}*/
    { id: "2303", name: "Ragunda" },
    { id: "2409", name: "Robertsfors" },
    { id: "1081", name: "Ronneby" },
    { id: "2031", name: "Rättvik" },
    /*{ M - 39 st}*/
    { id: "1981", name: "Sala" },
    { id: "0128", name: "Salem" },
    { id: "2181", name: "Sandviken" },
    { id: "0191", name: "Sigtuna" },
    { id: "1291", name: "Simrishamn" },
    { id: "1265", name: "Sjöbo" },
    { id: "1495", name: "Skara" },
    { id: "2482", name: "Skellefteå" },
    { id: "1904", name: "Skinnskatteberg" },
    { id: "1264", name: "Skurup" },
    { id: "1496", name: "Skövde" },
    { id: "2061", name: "Smedjebacken" },
    { id: "2283", name: "Sollefteå" },
    { id: "0163", name: "Sollentuna" },
    { id: "0184", name: "Solna" },
    { id: "2422", name: "Sorsele" },
    { id: "1427", name: "Sotenäs" },
    { id: "1230", name: "Staffanstorp" },
    { id: "1415", name: "Stenungsund" },
    { id: "0180", name: "Stockholm" },
    { id: "1760", name: "Storfors" },
    { id: "2421", name: "Storuman" },
    { id: "0486", name: "Strängnäs" },
    { id: "1486", name: "Strömstad" },
    { id: "2313", name: "Strömsund" },
    { id: "0183", name: "Sundbyberg" },
    { id: "2281", name: "Sundsvall" },
    { id: "1766", name: "Sunne" },
    { id: "1907", name: "Surahammar" },
    { id: "1214", name: "Svalöv" },
    { id: "1263", name: "Svedala" },
    { id: "1465", name: "Svenljunga" },
    { id: "1785", name: "Säffle" },
    { id: "2082", name: "Säter" },
    { id: "0684", name: "Sävsjö" },
    { id: "2182", name: "Söderhamn" },
    { id: "0582", name: "Söderköping" },
    { id: "0181", name: "Södertälje" },
    { id: "1083", name: "Sölvesborg" },
    /*{ T - 18 st}*/
    { id: "1435", name: "Tanum" },
    { id: "1472", name: "Tibro" },
    { id: "1498", name: "Tidaholm" },
    { id: "0360", name: "Tierp" },
    { id: "2262", name: "Timrå" },
    { id: "0763", name: "Tingsryd" },
    { id: "1419", name: "Tjörn" },
    { id: "1270", name: "Tomelilla" },
    { id: "1737", name: "Torsby" },
    { id: "0834", name: "Torsås" },
    { id: "1452", name: "Tranemo" },
    { id: "0687", name: "Tranås" },
    { id: "1287", name: "Trelleborg" },
    { id: "1488", name: "Trollhättan" },
    { id: "0488", name: "Trosa" },
    { id: "0138", name: "Tyresö" },
    { id: "0160", name: "Täby" },
    { id: "1473", name: "Töreboda" },
    /*{ U - 7 st}*/
    { id: "1485", name: "Uddevalla" },
    { id: "1491", name: "Ulricehamn" },
    { id: "2480", name: "Umeå" },
    { id: "0114", name: "Upplands Väsby" },
    { id: "0139", name: "Upplands-Bro" },
    { id: "0380", name: "Uppsala" },
    { id: "0760", name: "Uppvidinge" },
    /*{ V - 22 st}*/
    { id: "0584", name: "Vadstena" },
    { id: "0665", name: "Vaggeryd" },
    { id: "0563", name: "Valdemarsvik" },
    { id: "0115", name: "Vallentuna" },
    { id: "2021", name: "Vansbro" },
    { id: "1470", name: "Vara" },
    { id: "1383", name: "Varberg" },
    { id: "0187", name: "Vaxholm" },
    { id: "1233", name: "Vellinge" },
    { id: "0685", name: "Vetlanda" },
    { id: "2462", name: "Vilhelmina" },
    { id: "0884", name: "Vimmerby" },
    { id: "2404", name: "Vindeln" },
    { id: "0428", name: "Vingåker" },
    { id: "1442", name: "Vårgårda" },
    { id: "1487", name: "Vänersborg" },
    { id: "2460", name: "Vännäs" },
    { id: "0120", name: "Värmdö" },
    { id: "0683", name: "Värnamo" },
    { id: "0883", name: "Västervik" },
    { id: "1980", name: "Västerås" },
    { id: "0780", name: "Växjö" },
    /*{ Y - 2 st}*/
    { id: "0512", name: "Ydre" },
    { id: "1286", name: "Ystad" },
    /*{ Å - 7 st}*/
    { id: "1492", name: "Åmål" },
    { id: "2260", name: "Ånge" },
    { id: "2321", name: "Åre" },
    { id: "1765", name: "Årjäng" },
    { id: "1277", name: "Åstorp" },
    { id: "2463", name: "Åsele" },
    { id: "0561", name: "Åtvidaberg" },
    /*{ M - 5 st}*/
    { id: "0765", name: "Älmhult" },
    { id: "2039", name: "Älvdalen" },
    { id: "0319", name: "Älvkarleby" },
    { id: "2560", name: "Älvsbyn" },
    { id: "1292", name: "Ängelholm" },
    /*{ M - 11 st}*/
    { id: "1407", name: "Öckerö" },
    { id: "0509", name: "Ödeshög" },
    { id: "1880", name: "Örebro" },
    { id: "1257", name: "Örkelljunga" },
    { id: "2284", name: "Örnsköldsvik" },
    { id: "2380", name: "Östersund" },
    { id: "0117", name: "Österåker" },
    { id: "0382", name: "Östhammar" },
    { id: "1256", name: "Östra Göinge" },
    { id: "2513", name: "Överkalix" },
    { id: "2518", name: "Övertorneå" }
  ];
  return municipalityCollection;
}
