import React from "react";
import BlockLayout from "./../Layout/BlockLayout";
import ComponentHeader from "../Header/ComponentHeader";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import { useInView } from "react-intersection-observer";
import KeyFigureBig from "../Elements/KeyFigureBig";
import { periodToComma, isCurrentYear } from "../../Helpers/CommonHelper";
import SvgLayout from "./../Layout/SvgLayout";
import EmptySpace from "../Elements/EmptySpace";
import {
  headingLarge,
  FiguresWrapper,
  HeadingMunicipality,
  Line
} from "../../Util/StyleProps";

const TaxRateContainer = props => {
  const { data, sweden, blockColor, scroll } = props;

  let municipality1 = data[0];
  let municipality2 = data[1];

  let swedenTaxRate = sweden ? sweden.taxrate.taxRate : "(data saknas)";
  let swedenString = ` I Sverige betalar vi i genomsnitt ${periodToComma(
    swedenTaxRate
  )} procent i kommunal skatt (${
    sweden ? sweden.taxrate.year : "årtal saknas"
  }).`;

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  // Accessibility
  const ariaLabel = municipality => {
    const str = municipality
      ? isCurrentYear(municipality.year)
        ? "betalas"
        : "betalades"
      : "";

    return municipality
      ? `I ${
          municipality.name
        } kommun så ${str} det i genomsnitt ${municipality.taxRate.toFixed(
          2
        )} procent i kommunal skatt (${
          municipality.year ? "år " + municipality.year : "årtal saknas"
        })`
      : "";
  };

  return (
    <React.Fragment>
      <BlockLayout background={blockColor}>
        <ComponentHeader
          heading={"Detta betalar vi i skatt"}
          text={swedenString}
        />
        {municipality1 ? (
          <div ref={ref}>
            <FiguresWrapper maxWidth="1000px">
              {municipality1 ? (
                <SvgLayout width="50%">
                  <HeadingMunicipality
                    color={primaryColor}
                    aria-label={ariaLabel(municipality1)}
                  >
                    {municipality1.name}
                  </HeadingMunicipality>
                  <KeyFigureBig
                    triggerAnimation={inView}
                    delay={0.75}
                    color={primaryColor}
                    size={headingLarge}
                    value={periodToComma(municipality1.taxRate.toFixed(2))}
                    subText={"procent"}
                  />
                </SvgLayout>
              ) : (
                <div></div>
              )}

              <Line marginBottom="36px" />
              {municipality2 ? (
                <SvgLayout width="50%" marginLeft="30px">
                  <HeadingMunicipality
                    color={secondaryColor}
                    aria-label={ariaLabel(municipality2)}
                  >
                    {municipality2.name}
                  </HeadingMunicipality>
                  <KeyFigureBig
                    triggerAnimation={inView}
                    delay={1.75}
                    color={secondaryColor}
                    size={headingLarge}
                    value={periodToComma(municipality2.taxRate.toFixed(2))}
                    subText={"procent"}
                  />
                </SvgLayout>
              ) : (
                <SvgLayout width="50%">
                  <EmptySpace scroll={scroll} />
                </SvgLayout>
              )}
            </FiguresWrapper>
          </div>
        ) : null}
      </BlockLayout>
    </React.Fragment>
  );
};

export default TaxRateContainer;
