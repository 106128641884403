import React from "react";
import ComponentHeader from "../Header/ComponentHeader";
import styled from "styled-components";
import BubbleFlexSvg from "../Elements/BubbleFlexSvg";
import BlockLayout from "./../Layout/BlockLayout";
import SvgLayout from "./../Layout/SvgLayout";
import { useInView } from "react-intersection-observer";
import { numberWithSpaces, isCurrentYear } from "../../Helpers/CommonHelper";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import {
  HeadingMunicipality,
  Line,
  FiguresWrapper
} from "../../Util/StyleProps";
import EmptySpace from "../Elements/EmptySpace";
import HighLineChartLine from "../Charts/HighChartLineChart";

const IncomeContainer = props => {
  const { data, sweden, blockColor, scroll } = props;

  const [ref, inView, entry] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0.5
  });
  let municipality1 = data[0];
  let municipality2 = data[1];

  let lastYearsIncome = sweden.montelyincome
    ? sweden.montelyincome.values.slice(-1)[0]
    : "(data saknas)";
  let lastYearSweden = sweden.montelyincome
    ? sweden.montelyincome.years.slice(-1).pop()
    : "(Årtal saknas)";

  const noWrapIncome =
    "<span class='text-nowrap'>" +
    numberWithSpaces(lastYearsIncome) +
    "</span>";

  let subText = `I Sverige är den genomsnittliga inkomsten före skatt för personer som är mellan 20 och 64 år  ${noWrapIncome} kronor per månad (${lastYearSweden}).`;

  // Accessibility för personer som är mellan 20 och 64 år 
  const ariaLabel = municipality => {
    let year = municipality.years ? municipality.years.slice(-1).pop() : null;
    return municipality
      ? `I ${municipality.name} kommun ${
          isCurrentYear(municipality.year) ? "är" : "var"
        } den genomsnittliga inkomsten före skatt ${municipality.values
          .slice(-1)
          .pop()} kronor per månad (${year ? "år " + year : "årtal saknas"})`
      : "";
  };

  const Wrapper = styled.div`
    .highcharts-background {
      fill: none;
      background-color: #ffd478 !important;
    }
  `;

  return (
    <React.Fragment>
      <BlockLayout background={blockColor}>
        <ComponentHeader heading={"Så mycket tjänar vi"} text={subText} />
        <div ref={ref}>
          {municipality1 ? (
            <>
              <FiguresWrapper
                style={{
                  borderBottom: "1px dashed rgba(34,34,34,0.4)",
                  marginBottom: "20px"
                }}
              >
                {municipality1 ? (
                  <SvgLayout width="50%">
                    <HeadingMunicipality
                      marginBottom="30px"
                      color={primaryColor}
                      aria-label={ariaLabel(municipality1)}
                    >
                      {municipality1.name}
                    </HeadingMunicipality>
                    <SvgLayout ariaHidden={true}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={"100%"}
                        height={"100%"}
                        viewBox={`0 0 100 50`}
                      >
                        <BubbleFlexSvg
                          triggerAnimation={inView}
                          value={
                            municipality1
                              ? municipality1.values.slice(-1).pop()
                              : 0
                          }
                          label={"kronor"}
                          width={90}
                          delay={0.75}
                          hasBackground
                          color={primaryColor}
                        />
                      </svg>
                    </SvgLayout>
                  </SvgLayout>
                ) : (
                  <div></div>
                )}
                <Line marginBottom="36px" />
                {municipality2 ? (
                  <SvgLayout width="50%" marginLeft="30px">
                    <HeadingMunicipality
                      marginBottom="30px"
                      color={secondaryColor}
                      aria-label={ariaLabel(municipality2)}
                    >
                      {municipality2.name}
                    </HeadingMunicipality>
                    <SvgLayout ariaHidden={true}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={"100%"}
                        height={"100%"}
                        viewBox={`0 0 100 50`}
                      >
                        <BubbleFlexSvg
                          triggerAnimation={inView}
                          value={
                            municipality2
                              ? municipality2.values.slice(-1).pop()
                              : 0
                          }
                          label={"kronor"}
                          width={90}
                          delay={0.75}
                          hasBackground
                          color={secondaryColor}
                        />
                      </svg>
                    </SvgLayout>
                  </SvgLayout>
                ) : (
                  <SvgLayout width="50%">
                    <EmptySpace scroll={scroll} />
                  </SvgLayout>
                )}
              </FiguresWrapper>
              <SvgLayout
                paddingBottom="30px"
                ariaLabel={`Det här linjediagramet visar inkomstförändring före skatt i ${
                  municipality1.name
                } kommun ${
                  municipality2 ? "och " + municipality2.name + "kommun" : ""
                } `}
                useTabIndex
              >
                <Wrapper>
                  <HighLineChartLine
                    data={data}
                    startpoint={1991}
                    triggerAnimation={inView}
                  />
                </Wrapper>
              </SvgLayout>
            </>
          ) : null}
        </div>
      </BlockLayout>
    </React.Fragment>
  );
};

export default IncomeContainer;
