import React from "react";
import styled, { keyframes } from "styled-components";
import { Col, Row } from "react-bootstrap";
import { periodToComma } from "../../Helpers/CommonHelper";
import IconPath from "./../Elements/IconPath";

import {
  scaleInFigure,
  letterSpacingDruk,
  drukText
} from "../../Util/StyleProps";
import { device } from "../../Util/Breakpoints";

const Wrapper = styled.div`
  color: ${props => props.color};
  .MuiSvgIcon-root {
    background: #ffffff;
    border-radius: 50%;
  }
`;

const Span = styled.span`
  font-size: 12px;
  font-family: Roboto;
  line-height: 18px;
  padding-left: 10px;
`;

const Figure = styled.p`
  font-size: 30px;
  line-height: 100%;
  padding-left: 10px;
  font-family: ${drukText};
  letter-spacing: ${letterSpacingDruk};
  margin-top: 0;
  margin-bottom: 0;

  @media${device.mobileL} {
    font-size:40px;
  }

  first-child: nth-last-child(n + 3);
  animation: 2s ${props => (props.triggerAnimation ? scaleInFigure : "")} 0.5s
    cubic-bezier(0.175, 0.885, 0.275, 1.235);
  -webkit-animation: ${props => (props.triggerAnimation ? scaleInFigure : "")}
    0.5s cubic-bezier(0.175, 0.885, 0.275, 1.235);
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
`;

const Icon = styled.div`
  background: #fff;
  border-radius: 50%;
  height: 36px;
  width: 36px;

  @media${device.mobileL} {
    height: 46px;
    width: 46px;
  }
`;

const SvgWrapper = styled.div`
  position: relative;
  height: 36px;
  width: 36px;
  text-align: center;
  line-height: 36px;

  @media${device.mobileL} {
    height: 46px;
    width: 46px;
    line-height: 46px;
  }

  & > svg {
    width:24px;
    height: 24px;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    padding: 2px;

    @media${device.mobileL} {
      height: 36px;
      width: 36px;
    }
  }
`;

const KeyFigureNew = props => {
  const {
    value,
    color,
    iconName,
    alt,
    triggerAnimation,
    delay,
    size,
    unit,
    subText
  } = props;
  return (
    <Wrapper color={color}>
      <Col>
        <div className="row" style={{ marginBottom: "20px" }}>
          <Icon>
            <SvgWrapper>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <IconPath color={color} iconName={iconName} />
              </svg>
            </SvgWrapper>
          </Icon>
          <div>
            <Figure
              triggerAnimation={triggerAnimation}
              delay={delay}
              fontSize={size}
            >
              {periodToComma(value)}
              {unit}
            </Figure>
            <Span>{subText}</Span>
          </div>
        </div>
      </Col>
    </Wrapper>
  );
};
KeyFigureNew.defaultProps = {
  iconName: "arrow-down",
  alt: "Ikon"
};

export default KeyFigureNew;
