import React, { useState } from "react";
import BubbleFlexSvg from "../Elements/BubbleFlexSvg";
import BlockLayout from "./../Layout/BlockLayout";
import SvgLayout from "./../Layout/SvgLayout";
import { createMarkup } from "../../Helpers/CommonHelper";
import {
  HeadingMunicipality,
  Line,
  FiguresWrapper
} from "../../Util/StyleProps";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import { useInView } from "react-intersection-observer";
import EmptySpace from "../Elements/EmptySpace";

const FigureBoxPopulation = props => {
  const { municipality1, municipality2, year, scroll } = props;
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true
  });

  // Accessibility
  const ariaLabel = (municipality, year) => {
    return municipality
      ? `I slutet av ${year} var befolkningen i ${
          municipality.name
        } kommun ${municipality.values.slice(-1).pop()} personer`
      : "";
  };

  return (
    <React.Fragment>
      <div ref={ref}>
        <FiguresWrapper
          style={{
            borderBottom: "1px dashed rgba(34,34,34,0.4)",
            marginBottom: "20px"
          }}
        >
          {municipality1 ? (
            <SvgLayout width="50%" marginBottom="30px">
              <HeadingMunicipality
                marginBottom="30px"
                color={primaryColor}
                aria-label={ariaLabel(municipality1, year)}
              >
                {municipality1.name}
              </HeadingMunicipality>
              <SvgLayout ariaHidden={true}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={"100%"}
                  height={"100%"}
                  viewBox={`0 0 100 50`}
                >
                  <BubbleFlexSvg
                    triggerAnimation={inView}
                    x={10}
                    value={
                      municipality1 ? municipality1.values.slice(-1).pop() : 0
                    }
                    label={"personer"}
                    delay={0.75}
                    hasBackground
                    width={90}
                    color={primaryColor}
                    viewBoxHeight={50}
                  />
                </svg>
              </SvgLayout>
            </SvgLayout>
          ) : (
            <div></div>
          )}
          <Line marginBottom="36px" />
          {municipality2 ? (
            <SvgLayout width="50%" marginLeft="30px">
              <HeadingMunicipality
                marginBottom="30px"
                color={secondaryColor}
                aria-label={ariaLabel(municipality2, year)}
              >
                {municipality2.name}
              </HeadingMunicipality>
              <SvgLayout ariaHidden={true}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={"100%"}
                  height={"100%"}
                  viewBox={`0 0 100 50`}
                >
                  <BubbleFlexSvg
                    triggerAnimation={inView}
                    x={10}
                    value={
                      municipality2 ? municipality2.values.slice(-1).pop() : 0
                    }
                    label={"personer"}
                    delay={0.75}
                    width={90}
                    hasBackground
                    color={secondaryColor}
                    viewBoxHeight={50}
                  />
                </svg>
              </SvgLayout>
            </SvgLayout>
          ) : (
            <SvgLayout width="50%">
              <EmptySpace scroll={scroll} />
            </SvgLayout>
          )}
        </FiguresWrapper>
      </div>
    </React.Fragment>
  );
};

export default FigureBoxPopulation;
