import React from "react";
import styled from "styled-components";

export const SvgWrapper = styled.div`
max-width:${props => (props.maxWidth ? props.maxWidth : "100%")}
width:${props => props.width};
height:${props => props.height};
padding-top: ${props => props.paddingTop};
padding-bottom: ${props => props.paddingBottom};
padding-left: ${props => props.paddingLeft};
padding-right: ${props => props.paddingRight};
margin:${props => (props.center ? "0 auto" : "0")};
margin:${props => props.margin};
margin-top:${props => props.marginTop};
margin-left:${props => props.marginLeft};
`;

const SvgLayout = props => {
  const {
    tabIndex,
    useTabIndex,
    width,
    maxWidth,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    marginTop,
    marginLeft,
    children,
    ariaLabel,
    ariaHidden,
    center
  } = props;
  return (
    <SvgWrapper
      aria-hidden={ariaHidden}
      {...(useTabIndex && { tabIndex: tabIndex })}
      aria-label={ariaLabel}
      width={width}
      center={center}
      maxWidth={maxWidth}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      marginTop={marginTop}
      marginLeft={marginLeft}
    >
      {children}
    </SvgWrapper>
  );
};

SvgLayout.defaultProps = {
  width: "100%",
  maxWidth: "100%",
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: "10px",
  margin: 0,
  marginTop: 0,
  marginLeft: 0,
  tabIndex: 0,
  useTabIndex: false,
  ariaLabel: "",
  ariaHidden: false,
  center: false
};

export default SvgLayout;
