import React from "react";
import styled, { keyframes } from "styled-components";
import { fadeIn, slideInUp } from "react-animations";
import { createMarkup } from "../../Helpers/CommonHelper";
import LinePath from "./LinePathSvg";
import {
  circleTransition,
  drukText,
  letterSpacingDruk
} from "../../Util/StyleProps";

const fadeInAnimation = keyframes`${fadeIn}`;

const G = styled.g``;

const Bubble = styled.circle`
  -webkit-animation: 0.5s
    ${props => (props.triggerAnimation ? circleTransition : "")}
    cubic-bezier(0.175, 0.885, 0.275, 1.235);
  animation: 0.5s ${props => (props.triggerAnimation ? circleTransition : "")}
    cubic-bezier(0.175, 0.885, 0.275, 1.235);
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
  fill: #ffffff;
  width: 45px;
  height: 45px;
`;

const BarCircleValue = styled.foreignObject`
  fill: ${props => props.color};
  font-family: ${drukText};
  font-size: 16px;
  line-height: 33px;
  animation: 0.25s ${props => (props.triggerAnimation ? fadeInAnimation : "")};
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
  & > div {
    color: ${props => props.color};
    letter-spacing: ${letterSpacingDruk};
    text-align: center;
  }
  .smallPercent {
    font-size: 10px;
  }
`;

const Label = styled.text`
  fill: ${props => props.color};
  font-family: ${drukText};
  letter-spacing: ${letterSpacingDruk};
  font-size: 14px;
  line-height: 33px;
  animation: 0.25s ${props => (props.triggerAnimation ? fadeInAnimation : "")};
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
`;

const BubbleSvg = props => {
  const {
    x,
    y,
    rx,
    value,
    label,
    color,
    delay,
    lineType,
    triggerAnimation,
    hasBackground
  } = props;

  //var rounded = Math.round(value * 10) / 10;
  var fixedValue = value; // rounded.toFixed(1);

  if(value == "100,0"){
    fixedValue = "100";
  }

  const valueOffset = hasBackground ? 8 : 35;

  let percent = "<span>%</span>";

  if (value.length > 2) {
    if (value[1] != ",") {
      percent = '<span class="smallPercent">%</span>';
    }
  }

  return (
    <React.Fragment>
      {hasBackground ? (
        <React.Fragment>
          <Bubble
            delay={delay}
            triggerAnimation={triggerAnimation}
            cx={x + 23}
            cy={y + 1}
            r={22}
          />
          <LinePath color={color} lineType={lineType} x={x + 23} y={y + 24} />
        </React.Fragment>
      ) : (
        ""
      )}
      <BarCircleValue
        triggerAnimation={triggerAnimation}
        color={color}
        delay={delay + 0.4}
        width="40"
        height="40"
        y={y - 24 + valueOffset}
        x={x + 3}
      >
        <div>
          {fixedValue}
          <span dangerouslySetInnerHTML={createMarkup(percent)} />
        </div>
      </BarCircleValue>
    </React.Fragment>
  );
};

BubbleSvg.defaultProps = {
  value: 0,
  lineType: "strait"
};
export default BubbleSvg;
