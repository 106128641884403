import React from "react";
import BlockLayout from "./../Layout/BlockLayout";
import SvgLayout from "./../Layout/SvgLayout";
import ComponentHeader from "../Header/ComponentHeader";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import { calculateAverageAge, isCurrentYear } from "../../Helpers/CommonHelper";
import { useInView } from "react-intersection-observer";
import KeyFigureNew from "../Elements/KeyFigureNew";
import EmptySpace from "../Elements/EmptySpace";
import { periodToComma } from "../../Helpers/CommonHelper";
import {
  headingSmall,
  headingLarge,
  HeadingMunicipality,
  Line,
  FiguresWrapper
} from "../../Util/StyleProps";
import KeyFigureBig from "../Elements/KeyFigureBig";

const AgeContainer = props => {
  const { data, sweden, blockColor, scroll } = props;

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  //periodToComma
  const lastYear = sweden.age.year;
  const swedenMedium = sweden.age.total;
  const swedenString =
    "I Sverige är medelåldern " +
    periodToComma(swedenMedium) +
    " år. Medelåldern är " +
    periodToComma(sweden.age.female) +
    " år för kvinnor och " +
    periodToComma(sweden.age.male) +
    " år för män (" +
    lastYear +
    ").";

  let municipality1 = data[0];
  let municipality2 = data[1];

  // Accessibility
  const ariaLabel = municipality => {
    const str = municipality
      ? isCurrentYear(municipality.year)
        ? "är"
        : "var"
      : "";

    return municipality
      ? `År ${municipality.year} ${str} medelåldern i ${
          municipality.name
        } kommun ${
          municipality.total
        }år. Medelåldern för kvinnor ${str} ${
          municipality.female
        }år och medelåldern för män ${str} ${municipality.male} år.`
      : "";
  };

  return (
    <React.Fragment>
      <BlockLayout background={blockColor}>
        <ComponentHeader heading={"Så är vår medelålder"} text={swedenString} />
        {municipality1 ? (
          <div ref={ref}>
            <FiguresWrapper maxWidth="1000px">
              {municipality1 ? (
                <SvgLayout width="50%">
                  <HeadingMunicipality
                    color={primaryColor}
                    aria-label={ariaLabel(municipality1)}
                  >
                    {municipality1.name}
                  </HeadingMunicipality>
                  <KeyFigureBig
                    triggerAnimation={inView}
                    delay={0.75}
                    color={primaryColor}
                    size={headingLarge}
                    value={
                      municipality1.total
                    }
                    subText={"år är medelåldern"}
                  />
                  <KeyFigureNew
                    triggerAnimation={inView}
                    delay={0.75}
                    iconName={"female"}
                    alt="Ikon kvinna"
                    color={primaryColor}
                    size={headingSmall}
                    value={municipality1.female}
                    subText={" år för kvinnor"}
                  />
                  <KeyFigureNew
                    triggerAnimation={inView}
                    delay={0.75}
                    iconName={"male"}
                    alt="Ikon man"
                    color={primaryColor}
                    size={headingSmall}
                    value={municipality1.male}
                    subText={"år för män"}
                  />
                </SvgLayout>
              ) : (
                <div></div>
              )}

              <Line marginBottom="30px" />
              {municipality2 ? (
                <SvgLayout width="50%" marginLeft="30px">
                  <HeadingMunicipality
                    color={secondaryColor}
                    aria-label={ariaLabel(municipality2)}
                  >
                    {municipality2.name}
                  </HeadingMunicipality>
                  <KeyFigureBig
                    triggerAnimation={inView}
                    delay={1.75}
                    color={secondaryColor}
                    size={headingLarge}
                    value={
                      municipality2.total
                    }
                    subText={"år är medelåldern"}
                  />
                  <KeyFigureNew
                    triggerAnimation={inView}
                    delay={1.75}
                    iconName={"female"}
                    alt="Ikon kvinna"
                    color={secondaryColor}
                    size={headingSmall}
                    value={municipality2.female}
                    subText={"år för kvinnor"}
                  />
                  <KeyFigureNew
                    triggerAnimation={inView}
                    delay={1.75}
                    iconName={"male"}
                    alt="Ikon man"
                    color={secondaryColor}
                    size={headingSmall}
                    value={municipality2.male}
                    subText={"år för män"}
                  />
                </SvgLayout>
              ) : (
                <SvgLayout width="50%">
                  <EmptySpace scroll={scroll} />
                </SvgLayout>
              )}
            </FiguresWrapper>
          </div>
        ) : null}
      </BlockLayout>
    </React.Fragment>
  );
};

AgeContainer.defaultProps = {
  data: [],
  sweden: {}
};

export default AgeContainer;
