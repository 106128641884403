import React from "react";
import { secondaryColor } from "./../../Util/Colors";

const IconPath = props => {
  const { iconName, color } = props;

  switch (iconName) {
    case "arrow-downward":
      return (
        <>
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            fill={color}
            d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"
          />
        </>
      );

    case "arrow-forward":
      return (
        <>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fill={color}
            d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
          />
        </>
      );
    case "arrow-upward":
      return (
        <>
          <path fill="none" d="M0 0h24v24H0V0z"></path>
          <path
            fill={color}
            d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
          />
        </>
      );
    case "female":
      return (
        <path
          fill={color}
          d="M18 9c0-3.3-2.7-6-6-6S6 5.7 6 9c0 3 2.2 5.4 5 5.9V17H9v2h2v2h2v-2h2v-2h-2v-2.1c2.8-.5 5-2.9 5-5.9zM8 9c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
        />
      );
    case "male":
      return (
        <path
          fill={color}
          d="M18,4h-4v2h2.6l-3.1,3.1C12.5,8.4,11.3,8,10,8c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6c0-1.3-0.4-2.5-1.1-3.5L18,7.4V10h2V6V4
          H18z M10,18c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S12.2,18,10,18z"
        />
      );
    case "house":
      return (
        <path
          fill={color}
          d="M19 9.3V4h-3v2.6L12 3 2 12h3v8h5v-6h4v6h5v-8h3l-3-2.7zm-9 .7c0-1.1.9-2 2-2s2 .9 2 2h-4z"
        />
      );
    case "nature":
      return (
        <path
          fill={color}
          d="M13 16.12a7 7 0 006.17-6.95c0-3.87-3.13-7-7-7s-7 3.13-7 7A6.98 6.98 0 0011 16.06V20H5v2h14v-2h-6v-3.88z"
        />
      );
    case "spa":
      return (
        <>
          <path
            fill="none"
            d="M0 0h24v24H0V0zm13.97 21.49c-.63.23-1.29.4-1.97.51.68-.12 1.33-.29 1.97-.51zM12 22z"
          ></path>
          <path
            fill={color}
            d="M8.55 12zm10.43-1.61zM15.49 9.63c-.18-2.79-1.31-5.51-3.43-7.63a12.188 12.188 0 00-3.55 7.63c1.28.68 2.46 1.56 3.49 2.63 1.03-1.06 2.21-1.94 3.49-2.63zm-6.5 2.65c-.14-.1-.3-.19-.45-.29.15.11.31.19.45.29zm6.42-.25c-.13.09-.27.16-.4.26.13-.1.27-.17.4-.26zM12 15.45C9.85 12.17 6.18 10 2 10c0 5.32 3.36 9.82 8.03 11.49.63.23 1.29.4 1.97.51.68-.12 1.33-.29 1.97-.51C18.64 19.82 22 15.32 22 10c-4.18 0-7.85 2.17-10 5.45z"
          />
        </>
      );
    case "person":
      return (
        <>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fill={color}
            d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          />
        </>
      );
    case "person-add":
      return (
        <>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fill={color}
            d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          />
        </>
      );
    case "people":
      return (
        <>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fill={color}
            d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
          />
        </>
      );
    case "straighten":
      return (
        <>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fill={color}
            d="M21 6H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 10H3V8h2v4h2V8h2v4h2V8h2v4h2V8h2v4h2V8h2v8z"
          />
        </>
      );
    case "stripes":
      return (
        <>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fill={color}
            d="M19.51 3.08L3.08 19.51c.09.34.27.65.51.9.25.24.56.42.9.51L20.93 4.49c-.19-.69-.73-1.23-1.42-1.41zM11.88 3L3 11.88v2.83L14.71 3h-2.83zM5 3c-1.1 0-2 .9-2 2v2l4-4H5zm14 18c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41v-2l-4 4h2zm-9.71 0h2.83L21 12.12V9.29L9.29 21z"
          />
        </>
      );
    case "info-outline":
      return (
        <>
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            fill={color}
            d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          />
        </>
      );
    case "table-chart-outline":
      return (
        <>
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            fill={color}
            d="M20 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v3H5V5h15zm-5 14h-5v-9h5v9zM5 10h3v9H5v-9zm12 9v-9h3v9h-3z"
          />
        </>
      );
    case "place":
      return (
        <>
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            fill={color}
            d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
          />
        </>
      );

    default:
      return null;
  }
};

IconPath.defaultProps = {
  color: secondaryColor
};

export default IconPath;
