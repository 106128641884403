import React from "react";
import BlockLayout from "./../Layout/BlockLayout";
import SvgLayout from "./../Layout/SvgLayout";
import ComponentHeader from "../Header/ComponentHeader";
import DoubleBarSvg from "../Charts/DoubleBarSvg";
import {
  primaryColor,
  secondaryColor,
  sectionPopulationLocationColor
} from "../../Util/Colors";
import {
  Line,
  FiguresWrapper,
  HeadingMunicipality
} from "../../Util/StyleProps";
import EmptySpace from "../Elements/EmptySpace";
import { valuePairToPercent, isCurrentYear } from "../../Helpers/CommonHelper";
import { useInView } from "react-intersection-observer";

const PopulationLocationContainer = props => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  const { data, sweden, scroll } = props;

  let valueData = [];
  let valueArr = [];
  let swedenArr, swedenString;
  const lastYear = sweden.populationLocation.year;
  swedenArr = valuePairToPercent(
    sweden.populationLocation,
    ["urban", "countryside"],
    1
  );
  swedenString =
    "I Sverige bor " +
    swedenArr.pstring1 +
    "  procent i tätort och " +
    swedenArr.pstring2 +
    " procent utanför tätort (" +
    lastYear +
    ").";

  data.forEach((item, i) => {
    if (item) {
      valueData.push(valuePairToPercent(item, ["urban", "countryside"], 1));
      valueArr.push(valueData[i].value1);
      valueArr.push(valueData[i].value2);
    }
  });

  let municipality1 = valueData[0];
  let municipality2 = valueData[1];

  const max = Math.max(...valueArr);
  const maxColumnPairIdx = valueArr.indexOf(Math.max(...valueArr));
  const maxIsEven = maxColumnPairIdx % 2 == 0;

  const maxOffset = max;
  const height = 120;
  const width = 100;
  const topSpace = 64;

  let captions = ["tätort", "landsbygd"];

  // Accessibility
  const ariaLabel = municipality => {
    const str = municipality
      ? isCurrentYear(municipality.year)
        ? "bor"
        : "bodde"
      : "";

    return municipality
      ? `I ${municipality.name} ${str} ${municipality.pstring1} procent i tätort och ${municipality.pstring2} procent på landsbyggd (år ${municipality.year}).`
      : "";
  };

  const svgAriaLabel = municipality =>
    municipality
      ? `Det här stapeldiagramet gör jämförelser i befolkning mellan tätort och landsbygd i ${municipality.name} kommun.`
      : "";

  return (
    <React.Fragment>
      <BlockLayout background={sectionPopulationLocationColor}>
        <ComponentHeader
          heading={"Så många bor i och utanför tätort"}
          text={swedenString}
        />
        {municipality1 ? (
          <div ref={ref}>
            <FiguresWrapper maxWidth="1000px">
              {municipality1 ? (
                <SvgLayout width="50%">
                  <HeadingMunicipality
                    aria-label={ariaLabel(municipality1)}
                    color={primaryColor}
                  >
                    {municipality1.name}
                  </HeadingMunicipality>
                  <SvgLayout
                    ariaLabel={svgAriaLabel(municipality1)}
                    useTabIndex
                  >
                    <DoubleBarSvg
                      ariaId={"PopulationLocation1"}
                      triggerAnimation={inView}
                      idx={0}
                      width={width}
                      height={height}
                      topSpace={topSpace}
                      delay={0.75}
                      maxOffset={maxOffset}
                      maxIsEven={maxIsEven}
                      firstValue={municipality1.value1}
                      secondValue={municipality1.value2}
                      pstring1={municipality1.pstring1}
                      pstring2={municipality1.pstring2}
                      captions={captions}
                      color={primaryColor}
                      index={[0, 1]}
                      title={municipality1.name}
                      desc={svgAriaLabel(municipality1)}
                      hasLine
                    />
                  </SvgLayout>
                </SvgLayout>
              ) : (
                <div> </div>
              )}
              <Line marginBottom="36px" />
              {municipality2 ? (
                <SvgLayout width="50%" marginLeft="30px">
                  <HeadingMunicipality
                    aria-label={ariaLabel(municipality2)}
                    color={secondaryColor}
                  >
                    {municipality2.name}
                  </HeadingMunicipality>
                  <SvgLayout
                    ariaLabel={svgAriaLabel(municipality2)}
                    useTabIndex
                  >
                    <DoubleBarSvg
                      idx={1}
                      ariaId={"PopulationLocation2"}
                      width={width}
                      height={height}
                      topSpace={topSpace}
                      triggerAnimation={inView}
                      delay={1.75}
                      maxOffset={maxOffset}
                      maxIsEven={maxIsEven}
                      firstValue={municipality2.value1}
                      secondValue={municipality2.value2}
                      pstring1={municipality2.pstring1}
                      pstring2={municipality2.pstring2}
                      captions={captions}
                      color={secondaryColor}
                      index={[2, 3]}
                      title={municipality2.name}
                      desc={svgAriaLabel(municipality2)}
                    />
                  </SvgLayout>
                </SvgLayout>
              ) : (
                <SvgLayout width="50%">
                  <EmptySpace scroll={scroll} />
                </SvgLayout>
              )}
            </FiguresWrapper>
          </div>
        ) : null}
      </BlockLayout>
    </React.Fragment>
  );
};

PopulationLocationContainer.defaultProps = {
  data: [],
  sweden: {}
};

export default PopulationLocationContainer;
