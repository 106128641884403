export function population(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);

  let contentcodes = contentcode(["BE0101N1"]);
  let times = time(latestYear);
  return getQuery([region, contentcodes, times]);
}
export function accommodation(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let contentcodes = contentcode(["BO0501C1", "BO0501C2"]);
  let times = time(latestYear);
  let accType = accommodationType();
  return getQuery([region, accType, contentcodes, times]);
}
export function genderData(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let contentcodes = contentcode(["BE0101N1"]);
  let times = time(latestYear);
  let genders = gender();
  return getQuery([region, genders, contentcodes, times]);
}
export function genderAgeData(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let times = time(latestYear);
  let genders = genderAge();
  return getQuery([region, genders, times]);
}

export function populationChange(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let contentcodes = contentcode(["BE0101N2"]);
  let times = time(latestYear);
  return getQuery([region, contentcodes, times]);
}

export function electionData(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let contentcodes = contentcode(["ME0104B8"]);
  let times = time(latestYear);
  return getQuery([region, contentcodes, times]);
}

export function incomeData(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let t_age = age();
  let incomeClass = income_class();
  let contentcodes = contentcode(["HE0110K1"]);
  let times = time(latestYear);

  return getQuery([region, t_age, incomeClass, contentcodes, times]);
}

export function taxRateData(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let times = time(latestYear);
  let contentcodes = contentcode(["OE0101D1"]);
  return getQuery([region, times, contentcodes]);
}

export function landUseData(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let landuse = LandUseType();
  let times = time(latestYear);
  return getQuery([region, landuse, times]);
}

export function birthplaceData(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let birthplace = birthplaceType();
  let times = time(latestYear);
  return getQuery([region, birthplace, times]);
}

export function livingConditionData(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let household = householdType();
  let contentcodes = contentcode(["BE0101C¤"]);
  let times = time(latestYear);
  return getQuery([region, household, contentcodes, times]);
}

export function emplomentextentData(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);
  let t_age = allages();
  let emplomenttype = emplomentExtentType();
  let genders = gender();
  let times = time(latestYear);
  return getQuery([region, t_age, emplomenttype, genders, times]);
}

export function populationLocation(regionFilter, regionCode, latestYear) {
  let region = getRegion(regionFilter, regionCode);

  let typOmrQuery = typOmr();
  let contentcodes = contentcode(["MI0810AG"]);
  let times = time(latestYear);
  return getQuery([region, typOmrQuery, contentcodes, times]);
}

function getQuery(query = []) {
  return { query: query, response: { format: "json" } };
}

function getRegion(filter, region_values) {
  return {
    code: "Region",
    selection: { filter: filter, values: region_values }
  };
}

// function getGender() {
//   return { code: "Kon", selection: { filter: "item", values: ["1", "2"] } };
// }

function contentcode(content_code_values = []) {
  return {
    code: "ContentsCode",
    selection: { filter: "item", values: content_code_values }
  };
}
function time(latestYear) {
  if (latestYear) {
    return { code: "Tid", selection: { filter: "top", values: ["1"] } };
  } else {
    return {
      code: "Tid",
      selection: { filter: "all", values: ["197*", "198*", "199*", "20*"] }
    };
  }
}
function income_class() {
  return {
    code: "Inkomstklass",
    selection: { filter: "item", values: ["TOT"] }
  };
}
function age() {
  return { code: "Alder", selection: { filter: "item", values: ["20-64"] } };
}

function householdType() {
  return {
    code: "Hushallstyp",
    selection: {
      filter: "item",
      values: [
        "ESUB",
        "ESMB25",
        "ESMB24",
        "SMUB",
        "SBMB25",
        "SBMB24",
        "OVRIUB",
        "ÖMB25",
        "ÖMB24",
        "SAKNAS"
      ]
    }
  };
}

function allages() {
  return {
    code: "Alder",
    selection: {
      filter: "item",
      values: [
        "20-24",
        "25-29",
        "30-34",
        "35-39",
        "40-44",
        "45-49",
        "50-54",
        "55-59",
        "60-64"
      ]
    }
  };
}

function gender() {
  return {
    code: "Kon",
    selection: {
      filter: "item",
      values: ["1", "2"]
    }
  };
}

function genderAge() {
  return {
    code: "Kon",
    selection: {
      filter: "item",
      values: ["1", "2", "1+2"]
    }
  };
}

function emplomentExtentType() {
  return {
    code: "Sysselsattning",
    selection: {
      filter: "item",
      values: ["FÖRV", "EJFÖRV"]
    }
  };
}

function birthplaceType() {
  return {
    code: "Fodelseregion",
    selection: {
      filter: "item",
      values: ["09", "11"]
    }
  };
}

function LandUseType() {
  return {
    code: "Markanvandningsklass",
    selection: {
      filter: "item",
      values: ["11", "14", "211", "212", "3", "3227", "3824", "811"]
    }
  };
}

function accommodationType() {
  return {
    code: "Fastighetstyp",
    selection: {
      filter: "item",
      values: ["220"]
    }
  };
}

function typOmr() {
  return {
    code: "TypOmr",
    selection: {
      filter: "item",
      values: ["IT", "UT"]
    }
  };
}
