import React from "react";
import styled, { keyframes } from "styled-components";
import { HeadingMunicipality, Caption } from "../../Util/StyleProps";
import BubbleSvg from "../Elements/BubbleSvg";
import { Col } from "react-bootstrap";
import { fadeIn, slideInUp } from "react-animations";
import { createMarkup } from "../../Helpers/CommonHelper";
import { scaleLinear } from "d3-scale";

const slideInAnimation = keyframes`${slideInUp}`;
const fadeInAnimation = keyframes`${fadeIn}`;

const Wrapper = styled.div`
  // margin-top: -30px;
`;

const Svg = styled.svg`
  width: 125%;
  // min-height: 1px;
`;

const SvgBottom = styled.svg`
  margin-top: 0;
`;

const G = styled.g``;

const Rect = styled.rect`
  height: ${props => props.height}px;
  width: 35px;
  animation: 0.75s ${props => (props.triggerAnimation ? slideInAnimation : "")};
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
`;

const SingleBarSvg = props => {
  const {
    value,
    delay,
    triggerAnimation,
    color,
    title,
    titleId,
    caption,
    desc,
    descId,
    width,
    height,
    topSpace,
    maxOffset,
    hasBubbleBg,
    percentageString,
    lineType
  } = props;

  const y = scaleLinear()
    .domain([0, maxOffset])
    .nice()
    .range([0, height - topSpace]);

  return (
    <Col xs={6} style={{ padding: 0 }}>
      {title ? (
        <HeadingMunicipality color={color}>{title}</HeadingMunicipality>
      ) : null}
      <Wrapper>
        <Svg
          viewBox={`0 0 ${width / 2} ${height}`}
          aria-labelledby={titleId + " " + descId}
        >
          <title id={titleId}>{title}</title>
          <desc id={descId}>{desc}</desc>
          <G>
            <BubbleSvg
              triggerAnimation={triggerAnimation}
              hasBackground={hasBubbleBg}
              delay={0.5 + delay}
              x={1}
              y={height - 40 - y(value)}
              rx={50}
              label={value}
              value={percentageString}
              color={color}
              lineType={lineType}
            />
            <Rect
              triggerAnimation={triggerAnimation}
              delay={delay}
              x={7}
              y={height - y(value)}
              height={triggerAnimation ? y(value) : 0}
              fill={color}
            ></Rect>
          </G>
        </Svg>
        <Caption
          dangerouslySetInnerHTML={createMarkup(caption)}
          color={color}
          paddingLeft={"19%"}
        ></Caption>
      </Wrapper>
    </Col>
  );
};

SingleBarSvg.defaultProps = {
  caption: "stapeltext",
  title: null,
  hasBubbleBg: true
};

export default SingleBarSvg;
