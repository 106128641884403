import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import BlockLayout from "../Layout/BlockLayout";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";
import { device } from "../../Util/Breakpoints";
import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { municipalities } from "../../data/kommuner";
import Switch from "@material-ui/core/Switch";

import {
  findUsersMunicipality,
  getCurrentPosition,
  checkPermission,
  handleError
} from "../../data/GeoApi";
import Message from "./Message";

import {
  ptSerif,
  roboto,
  lineheight34,
  letterSpacingPT_serif
} from "../../Util/StyleProps";
import { primaryColor } from "../../Util/Colors";

const municipalitySelectorDefault = municipalities();
municipalitySelectorDefault.unshift({ id: 0, name: "Din position" });
var municipalitySelector = [];

const Heading = styled.h1`
  font-family: ${ptSerif};
  letter-spacing: ${letterSpacingPT_serif};
  font-weight: bold;
  font-size: 24px;
  line-height: ${lineheight34}
  font-style: normal;
  margin: 0px;

  @media${device.tablet}{
      font-size: 36px;
      line-height: 50px;
    }

`;

const TextFieldWrapper = styled.div`
  max-width: 250px;

  @media${device.tablet}{
    min-width:330px;
    max-width: 330px;
  }
`;

const ButtonWrapper = styled.div`
  .btn-primary:hover {
    background-color: ${primaryColor} !important;
    border-color: ${primaryColor} !important;
    text-decoration:underline;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: ${primaryColor} !important;
    border-color: ${primaryColor} !important;
    opacity: .82;
  }

  .btn-primary:disabled {
    background-color: ${primaryColor} !important;
    border-color: ${primaryColor} !important;
    opacity: .2;
    text-decoration:none;
  }

  & button {
    border-radius: 25px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 0 18px !important;
    line-height: 34px;
    width: 13em;
    background-color: ${primaryColor};
    border-color: ${primaryColor};
    font-family: ${roboto};
    font-weight: 500;
    font-size: 14px;

    @media${device.tablet}{ 
      padding: 0 24px !important;
      line-height: 42px;
    }
  }

 
`;

const MunicipalityWrapper = styled.div`

& label {
  margin-bottom: 0;
  line-height: ${lineheight34}
}

.MuiFormControl-marginNormal {
  margin-bottom: 0;
  margin-top: 0 !important;
}
// Line under input-field

.MuiInput-underline:before {
    border-bottom: 1px solid ${primaryColor};
    border-bottom-color: ${primaryColor};
    opacity: 0.47;
    bottom:0;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid ${primaryColor};
    border-bottom-color: ${primaryColor};
    opacity: 0.47;
}

& input {
  font-family: ${ptSerif};
  letter-spacing: ${letterSpacingPT_serif};
  font-weight:bold;
  font-size: 24px;
  line-height: ${lineheight34} ;
  height:34px;
}

@media${device.tablet}{
  display:flex;
  flex-wrap: wrap;

  .MuiInput-underline:before {
    bottom:2px !important;
}

  & input {
    flex: 1 0 50%;
    font-size: 36px;
    line-height: 50px;
    height: 50px;
   
  }
  & label {
    flex: 1 0 50%;
    line-height: 50px;
    min-width:376px;
    max-width:376px;
  }
}
`;

const classes = {
  root: {
    width: 300,
    fontSize: 20
  },
  TextField: {
    "& input": {
      color: "#1E00BE",
      width: 0,
      padding: 0
    },
    "& .MunicipalitySelector-Autocomplete-4": {
      marginBottom: 0,
      marginTop: 0,
      lineHeight: 42
    },
    "& svg": {
      color: "#1E00BE"
    }
  },
  Autocomplete: {
    alignContent: "center",
    lineHeight: 0,
    "& span": {
      width: "100%"
    }
  },
  option: {
    "& > span": {
      marginRight: 10,
      fontSize: 30
    }
  }
};

export class MunicipalitySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      municipalityid1: props.requestedMunicipalities["1"]
        ? props.requestedMunicipalities["1"].id
        : null,
      municipalityid2: props.requestedMunicipalities["2"]
        ? props.requestedMunicipalities["2"].id
        : null,
      municipalityname1: props.requestedMunicipalities["1"]
        ? props.requestedMunicipalities["1"].name
        : null,
      municipalityname2: props.requestedMunicipalities["2"]
        ? props.requestedMunicipalities["2"].name
        : null,
      hasmessage: false,
      message: null,
      municipalitySelector: municipalitySelectorDefault,
      hasAcceptedGeoTracking: false,
      userCurrentMuncipality: props.requestedMunicipalities["1"],
      userCurrentMuncipality2: props.requestedMunicipalities["2"]
    };
    this.onMunicipalityChange1 = this.onMunicipalityChange1.bind(this);
    this.onMunicipalityChange2 = this.onMunicipalityChange2.bind(this);
    this.toogleGeoPosition = this.toogleGeoPosition.bind(this);
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (this.state.municipalityid1 || this.state.municipalityid2) {
      this.preloadMuncicipality();
    } else {
      if (!isSafari) {
        this.checkIfUserHasAcceptedGeoTracking();
      }
    }
  }

  preloadMuncicipality = async () => {
    await this.fetchMunicipalityData();
  };

  getUsersGeoMuncicipality = async () => {
    try {
      const { coords } = await getCurrentPosition();
      municipalitySelector = await findUsersMunicipality(
        coords.latitude,
        coords.longitude
      );
      this.updateMunicipalityUrl(municipalitySelector[0].id, this.state.municipalityid2);

      this.setState({
        hasAcceptedGeoTracking: true,
        municipalityid1: municipalitySelector[0].id,
        municipalityname1: municipalitySelector[0].name,
        userCurrentMuncipality: municipalitySelector[0],
        municipalitySelector: municipalitySelector
      });
    } catch (error) {
      var msg = null;

      switch (error.code) {
        case error.PERMISSION_DENIED:
          msg =
            "Du måste tillåta webbläsaren att känna av var du befinner dig. Det gör du i webbläsarens inställningar.";
          break;
        case error.POSITION_UNAVAILABLE:
          msg =
            "Vi hittar tyvärr inte din plats, du får välja din egen position.";
          break;
        case error.UNKNOWN_ERROR:
          msg = "Något gick fel, försök igen.";
          break;
        default:
          msg = "Något gick fel, försök igen.";
      }
      this.setState({
        hasmessage: true,
        message: msg
      });
    }
  };
  //Checks whether the user has previously approved geo-positioning
  checkIfUserHasAcceptedGeoTracking = async () => {
    var permission = await checkPermission();
    if (permission === "granted") {
      this.getUsersGeoMuncicipality();
    } else {
      this.setState({
        municipalitySelector: municipalitySelectorDefault
      });
    }
  };

  fetchMunicipalityData = () => {
    this.props.datahandler([
      this.state.municipalityid1,
      this.state.municipalityid2
    ]);
  };

  updateMunicipalityUrl = (m1, m2) => {
    if (window.history.pushState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?id1=" +
        m1 +
        "&id2=" +
        m2;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  onMunicipalityChange1 = (event, object) => {
    if (object) {
      if (object.id === 0) {
        this.getUsersGeoMuncicipality();
      } else {
        this.updateMunicipalityUrl(object.id, this.state.municipalityid2);
        this.setState({
          municipalityid1: object.id,
          municipalityname1: object.name,
          userCurrentMuncipality: object
        });
      }
    } else {
      this.updateMunicipalityUrl(null, this.state.municipalityid2);
      this.setState({
        municipalityid1: null,
        municipalityName1: null,
        userCurrentMuncipality: null
      });
    }
  };

  onMunicipalityChange2 = (event, object) => {
    if (object) {
      this.updateMunicipalityUrl(this.state.municipalityid1, object.id);
      this.setState({
        municipalityid2: object.id,
        municipalityname2: object.name,
        userCurrentMuncipality2: object
      });
    } else {
      this.updateMunicipalityUrl(this.state.municipalityid1, null);
      this.setState({
        municipalityid2: null,
        municipalityName2: null,
        userCurrentMuncipality2: object
      });
    }
  };

  //Gives the user a choice to enable geopositioning
  toogleGeoPosition() {
    if (this.state.hasAcceptedGeoTracking) {
      this.setState({ hasmessage: true });
      this.setState({
        message:
          "Om du vill stänga av positionering, måste du göra det i din webbläsare."
      });
    } else {
      this.getUsersGeoMuncicipality();
    }
  }

  handleClickMessageBox = () => {
    this.setState({ hasmessage: false });
  };

  render() {
    return (
      <BlockLayout paddingBottom="10px">
        <Message
          heading="Vi kan inte se var du är!"
          message={this.state.message}
          show={this.state.hasmessage}
          handleClickMessageBox={this.handleClickMessageBox}
        />
        <MunicipalityWrapper ref={this.props.refMunicipalitySelector}>
          <Form.Label>
            <Heading>Jag vill se kommunen</Heading>
          </Form.Label>

          <Autocomplete
            id="municipality1"
            onChange={this.onMunicipalityChange1}
            className={this.props.classes.Autocomplete}
            options={this.state.municipalitySelector}
            value={this.state.userCurrentMuncipality}
            getOptionLabel={option => option.name}
            getOptionDisabled={option =>
              option.id === this.state.municipalityid2
            }
            filterOptions={createFilterOptions({
              matchFrom: "start",
              stringify: option => option.name
            })}
            renderInput={params => (
              <TextFieldWrapper>
                <TextField
                  className={this.props.classes.TextField}
                  {...params}
                  placeholder="Välj kommun"
                  margin="normal"
                  fullWidth
                />
              </TextFieldWrapper>
            )}
          />

          <Form.Label>
            <Heading> och jämföra den med </Heading>
          </Form.Label>

          <Autocomplete
            id="municipality2"
            onChange={this.onMunicipalityChange2}
            className={this.props.classes.Autocomplete}
            options={municipalitySelectorDefault}
            value={this.state.userCurrentMuncipality2}
            getOptionLabel={option => option.name}
            getOptionDisabled={option =>
              option.id === this.state.municipalityid1
            }
            filterOptions={createFilterOptions({
              matchFrom: "start",
              stringify: option => option.name
            })}
            renderInput={params => (
              <TextFieldWrapper>
                <TextField
                  className={this.props.classes.TextField}
                  {...params}
                  placeholder="Välj kommun"
                  margin="normal"
                  fullWidth
                />
              </TextFieldWrapper>
            )}
          />
        </MunicipalityWrapper>
        <ButtonWrapper>
          <Button
            onClick={this.fetchMunicipalityData}
            className={this.props.classes.Button}
            disabled={
              this.state.municipalityid1 || this.state.municipalityid2
                ? false
                : true
            }
          >
            Visa kommunsiffror
          </Button>
        </ButtonWrapper>
      </BlockLayout>
    );
  }
}
export default withStyles(classes)(MunicipalitySelector);
