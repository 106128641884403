import axios from "axios";
import * as query from "./Queries";
import { getMunicipalityName, hasMunicipalityName } from "../../data/kommuner";
import { TableSortLabel } from "@material-ui/core";
import {
  checkForValues,
  GetUniqueLocations,
  getBrowserQueryParams
} from "../../Helpers/CommonHelper";
import { getAllYearsProd, getAllYearsDev } from "../..//data/TimeSpan";
import { Filter } from "@material-ui/icons";
const FILTER_POP_KINGDOM = "vs:RegionRiket99";
const FILTER_EjAggr = "EjAggr";
const FILTER_POP_COMPARE_MUNICIPALITY = "vs:RegionKommun07";
const SWEDEN_CODE = "00";
const ENVIROMENT = "Prod";

const api = axios.create({
  baseURL: process.env.REACT_APP_PXWEB_API_URL
});

//Population - (Så många är vi)
//www.statistikdatabasen.scb.se/sq/77727 (Skarp data fram till 2019)

export async function getPopulation(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let popQyuery = query.population(
    regionFilter,
    regionCode.filter(data => data),
    false
  );
  return api({
    method: "post",
    url: "BE/BE0101/BE0101A/BefolkningNy",
    data: popQyuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(popMuncipality => {
      let popData = popMuncipality.data.data;

      let locations = GetUniqueLocations(popData);
      let data = ApiPopulationResponseToArray(popData, locations, regionFilter);
      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(popQyuery));
    });
}

function ApiPopulationResponseToArray(rawData, locations, regionFilter) {
  let data = [];

  locations.forEach((location, idx) => {
    data.push({ values: [], years: [] });
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        if (regionFilter === FILTER_POP_KINGDOM) {
          data[idx].values.push({
            year: raw.key[1],
            population: parseInt(raw.values[0])
          });
        } else {
          data[idx].values.push(parseInt(raw.values[0]));
        }
        data[idx].years.push(raw.key[1]);
      }
    });
  });
  return data;
}

//PopulationChange - (Så har befolkningen förändrats)
//www.statistikdatabasen.scb.se/sq/77731 (Skarp data fram till 2019)

export function getPopulationChange(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let popQyuery = query.populationChange(
    regionFilter,
    regionCode.filter(data => data),
    true
  );
  return api({
    method: "post",
    url: "BE/BE0101/BE0101A/BefolkningNy",
    data: popQyuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(popMuncipality => {
      let popData = popMuncipality.data.data;
      const locations = GetUniqueLocations(popData);
      const data = ApiResponseToPopulationChangeArray(popData, locations);

      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(popQyuery));
    });
}

function ApiResponseToPopulationChangeArray(rawData, locations) {
  let data = [];

  locations.forEach((location, idx) => {
    data.push({});
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        data[idx].year = raw.key[1];
        data[idx].value = raw.values[0];
      }
    });
  });

  return data;
}

//Gender - (Så ser könsfördelningen ut)
//www.statistikdatabasen.scb.se/sq/77742 (Skarp data fram till 2019)

export async function getGender(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let genderQuery = query.genderData(
    regionFilter,
    regionCode.filter(data => data),
    true
  );
  return api({
    method: "post",
    url: "BE/BE0101/BE0101A/BefolkningNy",
    data: genderQuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(genderMunicipality => {
      let keys = ["male", "female"];
      let genData = genderMunicipality.data.data;

      const locations = GetUniqueLocations(genData);
      const data = ApiGenderResponseToArray(genData, locations, keys);

      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(genderQuery));
    });
}

function ApiGenderResponseToArray(rawData, locations, keys) {
  let data = [];

  locations.forEach((location, idx) => {
    data.push({});
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        if(keys[raw.key[1] - 1]){
            data[idx][keys[raw.key[1] - 1]] = raw.values[0];
        }
        if(raw.key[1] == "1+2"){
            data[idx].total = raw.values[0];
        }

        data[idx].year = raw.key[2];
      }
    });
  });
  return data;
}

//Age - (Så ser vår medelålder ut)
//www.statistikdatabasen.scb.se/sq/77747 (Skarp data fram till 2018)

export function getGenderAge(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let genderQuery = query.genderAgeData(
    regionFilter,
    regionCode.filter(data => data),
    true
  );

  return api({
    method: "post",
    url: "BE/BE0101/BE0101B/BefolkningMedelAlder",
    data: genderQuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(genderMunicipality => {
      let keys = ["male", "female"];
      let genData = genderMunicipality.data.data;
      const locations = GetUniqueLocations(genData);
      const data = ApiGenderResponseToArray(genData, locations, keys);

      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(genderQuery));
    });
}

//BirthPlace - (Här är vi födda)
//www.statistikdatabasen.scb.se/sq/77753 (Skarp data fram till 2019)

export function getBirthPlace(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let popQyuery = query.birthplaceData(
    regionFilter,
    regionCode.filter(data => data),
    true
  );
  return api({
    method: "post",
    url: "BE/BE0101/BE0101E/InrUtrFoddaRegAlKon",
    data: popQyuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(popMuncipality => {
      let birthPlaceData = popMuncipality.data.data;
      const locations = GetUniqueLocations(birthPlaceData);
      const data = ApiBirthPlaceResponseToArray(birthPlaceData, locations);

      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(popQyuery));
    });
}

function ApiBirthPlaceResponseToArray(rawData, locations) {
  let data = [];
  locations.forEach((location, idx) => {
    data.push({});
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        if (checkForValues(raw.values[0])) {
          switch (parseInt(raw.key[1])) {
            case 9:
              data[idx].borninsweden = Number(raw.values[0]);
              break;
            case 11:
              data[idx].bornabroud = Number(raw.values[0]);
              break;
          }
        }
        data[idx].total = data[idx].year = raw.key[2];
      }
    });
  });
  data.forEach((element, index) => {
    data[index].total = Number(element.bornabroud + element.borninsweden);
  });
  return data;
}

//PopulationLocation - (Så många bor i och utanför tätort)
//www.statistikdatabasen.scb.se/sq/77735 (Skarp data fram till 2018)

export async function getPopulationLocation(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let locationQuery = query.populationLocation(
    regionFilter,
    regionCode.filter(data => data),
    true
  );

  return api({
    method: "post",
    url: "MI/MI0810/MI0810A/BefLandInvKvmTO",
    data: locationQuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(popLocation => {
      let popLocationData = popLocation.data.data;
      const locations = GetUniqueLocations(popLocationData);
      const data = ApiResponseToPopulationLocationArray(
        popLocationData,
        locations
      );

      return data;
    })
    .catch(err => {
      console.error(err);
    });
}

function ApiResponseToPopulationLocationArray(rawData, locations) {
  let data = [];
  locations.forEach((location, idx) => {
    data.push({});
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        if (raw.key[1] === "IT") {
          data[idx].urban = raw.values[0];
        }
        if (raw.key[1] === "UT") {
          data[idx].countryside = raw.values[0];
        }
        data[idx].year = raw.key[2];
      }
    });
  });

  return data;
}

//LandUse - (Så används marken)
//www.statistikdatabasen.scb.se/sq/79088 (Skarp data fram till 2015)

export function getLandUse(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let popQyuery = query.landUseData(
    regionFilter,
    regionCode.filter(data => data),
    true
  );
  return (
    api({
      method: "post",
      url: "MI/MI0803/MI0803A/MarkanvKn",
      data: popQyuery,
      headers: {
        "Content-Type": "text/plain;charset=utf-8"
      }
    })
      //Jordbruk (åkermark och betesmark) 11 och 14
      //Skogsmark (produktiv och icke produktiv skogsmark) 211 och 212
      //Bebyggelse (bebyggd mark och tillhörande mark) 3
      //Övrigt (täkter/gruvområden, golfbanor/skidpistar, övrig mark) 3227, 3824 och 811
      .then(popMuncipality => {
        let landuseData = popMuncipality.data.data;
        const locations = GetUniqueLocations(landuseData);
        const data = ApiLandUseResponseToArray(landuseData, locations);

        return data;
      })
      .catch(err => {
        console.error(err);
        console.log(JSON.stringify(popQyuery));
      })
  );
}

function ApiLandUseResponseToArray(rawData, locations) {
  let data = [];

  locations.forEach((location, idx) => {
    data.push({ agriculture: 0, forest: 0, settlements: 0, other: 0 });
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        data[idx].year = raw.key[2];

        if (checkForValues(raw.values)) {
          switch (parseInt(raw.key[1])) {
            case 11:
            case 14:
              data[idx].agriculture += Number(raw.values);
              break;
            case 211:
            case 212:
              data[idx].forest += Number(raw.values);
              break;
            case 3:
              data[idx].settlements += Number(raw.values);
              break;
            case 3227:
            case 3824:
            case 811:
              data[idx].other += Number(raw.values);
              break;
          }
        }
      }
    });
  });

  data.forEach((item, idx) => {
    data[idx].total = Number(
      item.agriculture + item.forest + item.settlements + item.other
    );
  });

  return data;
}

//Income - (Så mycket tjänar vi)
//www.statistikdatabasen.scb.se/sq/78762 (Skarp data fram till 2018)

export function getIncome(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let popQyuery = query.incomeData(
    regionFilter,
    regionCode.filter(data => data),
    false
  );
  return api({
    method: "post",
    url: "HE/HE0110/HE0110A/SamForvInk2",
    data: popQyuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(popMuncipality => {
      let incomeData = popMuncipality.data.data;
      const locations = GetUniqueLocations(incomeData);
      const data = ApiResponseToIncomeArray(incomeData, locations);

      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(popQyuery));
    });
}

function ApiResponseToIncomeArray(rawData, locations) {
  let data = [];

  locations.forEach((location, idx) => {
    data.push({ values: [], years: [] });
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        data[idx].values.push(parseInt((raw.values[0] / 12) * 1000));
        data[idx].years.push(raw.key[3]);
      }
    });
  });

  return data;
}

//EmplomentExtent - (Så många är i arbete)
//www.statistikdatabasen.scb.se/sq/78758 (Skarp data fram till 2018)

export function getEmplomentExtent(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let popQyuery = query.emplomentextentData(
    regionFilter,
    regionCode.filter(data => data),
    true
  );
  return api({
    method: "post",
    url: "AM/AM0207/AM0207H/BefSyssAldKonK",
    data: popQyuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(popMuncipality => {
      let emplomentExtentData = popMuncipality.data.data;
      const locations = GetUniqueLocations(emplomentExtentData);
      const data = ApiEmploymentResponseToArray(emplomentExtentData, locations);

      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(popQyuery));
    });
}

function ApiEmploymentResponseToArray(rawData, locations) {
  let data = [];

  locations.forEach((location, idx) => {
    data.push({
      men_in_work: 0,
      women_in_work: 0,
      men_not_working: 0,
      women_not_working: 0
    });
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        data[idx].year = raw.key[4];

        if (checkForValues(raw.values)) {
          switch (raw.key[1]) {
            case "FÖRV":
              if (parseInt(raw.key[3]) === 1) {
                data[idx].men_in_work += Number(raw.values);
              } else {
                data[idx].women_in_work += Number(raw.values);
              }
              break;
            case "EJFÖRV":
              if (parseInt(raw.key[3]) === 1) {
                data[idx].men_not_working += Number(raw.values);
              } else {
                data[idx].women_not_working += Number(raw.values);
              }
              break;
          }
        }
      }
    });
  });

  data.forEach((item, idx) => {
    data[idx].men_and_women_in_work = Number(
      item.men_in_work + item.women_in_work
    );
    data[idx].men_and_women_not_working = Number(
      item.men_not_working + item.women_not_working
    );
    data[idx].total = Number(
      item.men_not_working +
        item.women_not_working +
        item.men_in_work +
        item.women_in_work
    );
  });

  return data;
}

//Accommodation - (Så mycket kostar våra hus)
//www.statistikdatabasen.scb.se/sq/79015 (Skarp data fram till 2018)

export function getAccommodation(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let accQuery = query.accommodation(
    regionFilter,
    regionCode.filter(data => data),
    true
  );

  return api({
    method: "post",
    url: "BO/BO0501/BO0501B/FastprisSHRegionAr",
    data: accQuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(accMunicipality => {
      let accData = accMunicipality.data.data;
      const locations = GetUniqueLocations(accData);
      const data = ApiAccResponseToArray(accData, locations);

      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(accQuery));
    });
}

function ApiAccResponseToArray(rawData, locations) {
  let data = [];

  locations.forEach((location, idx) => {
    data.push({});
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        data[idx].year = raw.key[2];
        data[idx].amount = raw.values[0];
        data[idx].averagePrice = raw.values[1];
      }
    });
  });

  return data;
}

//LivingCondition - (Så ser våra hushåll ut)
//www.statistikdatabasen.scb.se/sq/78935 (Skarp data fram till 2018)

export function getLivingCondition(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let popQyuery = query.livingConditionData(
    regionFilter,
    regionCode.filter(data => data),
    true
  );
  return api({
    method: "post",
    url: "BE/BE0101/BE0101S/HushallT05",
    data: popQyuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(popMuncipality => {
      let livingConditionData = popMuncipality.data.data;
      const locations = GetUniqueLocations(livingConditionData);
      const data = ApiLivingConditionResponseToArray(
        livingConditionData,
        locations
      );
      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(popQyuery));
    });
}

function ApiLivingConditionResponseToArray(rawData, locations) {
  let data = [];
  locations.forEach((location, idx) => {
    data.push({ single: 0, cohabiting: 0, other: 0 });
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        if (checkForValues(raw.values[0])) {
          switch (raw.key[1]) {
            case "ESUB":
            case "ESMB25":
            case "ESMB24":
              data[idx].single += Number(raw.values[0]);
              break;
            case "SMUB":
            case "SBMB25":
            case "SBMB24":
              data[idx].cohabiting += Number(raw.values[0]);
              break;
            case "OVRIUB":
            case "ÖMB25":
            case "ÖMB24":
            case "SAKNAS":
              data[idx].other += Number(raw.values[0]);
              break;
          }
        }
        data[idx].total = data[idx].year = raw.key[2];
      }
    });
  });
  data.forEach((element, index) => {
    data[index].total = Number(
      element.single + element.cohabiting + element.other
    );
  });
  return data;
}

//TaxRate - (Detta betalar vi i skatt)
//www.statistikdatabasen.scb.se/sq/78795 (innevarande år)

export function getTaxRate(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let popQyuery = query.taxRateData(
    regionFilter,
    regionCode.filter(data => data),
    true
  );
  return api({
    method: "post",
    url: "OE/OE0101/Kommunalskatter2000",
    data: popQyuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(popMuncipality => {
      let taxRateData = popMuncipality.data.data;
      const locations = GetUniqueLocations(taxRateData);
      const data = ApiResponseToTaxRateArray(taxRateData, locations);

      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(popQyuery));
    });
}

function ApiResponseToTaxRateArray(rawData, locations) {
  let data = [];

  locations.forEach((location, idx) => {
    data.push({ values: [], years: [] });
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        data[idx].year = raw.key[1];
        data[idx].taxRate = parseFloat(raw.values[0]);
      }
    });
  });

  return data;
}

//Election - (Så många av oss röstar)
//www.statistikdatabasen.scb.se/sq/78799 (Skarp data fram till 2018)

export function getElection(
  regionFilter,
  regionCode1 = null,
  regionCode2 = null
) {
  let regionCode = [regionCode1, regionCode2];
  let popQyuery = query.electionData(
    regionFilter,
    regionCode.filter(data => data),
    true
  );
  return api({
    method: "post",
    url: "ME/ME0104/ME0104D/ME0104T4",
    data: popQyuery,
    headers: {
      "Content-Type": "text/plain;charset=utf-8"
    }
  })
    .then(popMuncipality => {
      let electionData = popMuncipality.data.data;
      const locations = GetUniqueLocations(electionData);
      const data = ApiResponseToElectionArray(electionData, locations);
      return data;
    })
    .catch(err => {
      console.error(err);
      console.log(JSON.stringify(popQyuery));
    });
}

function ApiResponseToElectionArray(rawData, locations) {
  let data = [];

  locations.forEach((location, idx) => {
    data.push({ values: [], years: [] });
    rawData.forEach(raw => {
      if (raw.key[0] == location) {
        data[idx].name = getMunicipalityName(raw.key[0]);
        data[idx].id = raw.key[0];
        data[idx].year = raw.key[1];
        data[idx].electionvalue = raw.values[0];
      }
    });
  });

  return data;
}

export function getRequestedMunicipalities() {
  const bp = getBrowserQueryParams();
  let munObj = { "1": null, "2": null };

  if (bp.id1 && bp.id1[0] && hasMunicipalityName(bp.id1[0])) {
    munObj["1"] = {
      id: bp.id1[0],
      name: getMunicipalityName(bp.id1[0])
    };
  }

  if (bp.id2 && bp.id2[0] && hasMunicipalityName(bp.id2[0])) {
    munObj["2"] = {
      id: bp.id2[0],
      name: getMunicipalityName(bp.id2[0])
    };
  }

  return munObj;
}
