import React from "react";
import styled, { keyframes } from "styled-components";
import { Col } from "react-bootstrap";
import BubbleSvg from "../Elements/BubbleSvg";
import { scaleLinear } from "d3-scale";
import {
  drukText,
  fontSizeSmall,
  HeadingMunicipality,
  CaptionForeignObject
} from "../../Util/StyleProps";
import { fadeIn, slideInUp } from "react-animations";
import { createMarkup } from "../../Helpers/CommonHelper";
import { ContactlessOutlined } from "@material-ui/icons";

const slideInAnimation = keyframes`${slideInUp}`;

const Wrapper = styled.div`
  position: relative;
  &.index1 {
    &:after {
      content: "";
      position: absolute;
      top: 35px;
      bottom: -20px;
      border-left: 1px solid #000;
      left: -15px;
    }
  }
`;
const Svg = styled.svg`
  margin-top: 30px;
  width: 100%;
`;
const G = styled.g``;
const Rect = styled.rect`
  height: ${props => props.height}px;
  width: 35px;
  animation: 0.75s ${props => (props.triggeranimation ? slideInAnimation : "")};
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
`;

const DoubleBarSvg = props => {
  const indexDelays = [0, 0.2, 0.35, 0.45];
  const titleId = props.ariaId ? props.ariaId + "Title" : "";
  const descId = props.ariaId ? props.ariaId + "Desc" : "";
  const {
    idx,
    height,
    width,
    maxOffset,
    maxIsEven,
    captions,
    title,
    desc,
    color,
    triggeranimation,
    firstValue,
    secondValue,
    index,
    pstring1,
    pstring2
  } = props;

  const y = scaleLinear()
    .domain([0, maxOffset])
    .nice()
    .range([0, height - 0]);

  return (
    <Col xs={6}>
      <HeadingMunicipality x={10} y={20} color={color}>
        {title}
      </HeadingMunicipality>
      <Wrapper className={"index" + idx}>
        <Svg
          viewBox={"0 0 " + width + " " + height}
          aria-labelledby={titleId + " " + descId}
        >
          <title id={titleId}>{title}</title>
          <desc id={descId}>{desc}</desc>
          <G>
            <BubbleSvg
              triggeranimation={triggeranimation}
              hasBackground={maxIsEven}
              delay={0.5 + indexDelays[index[0]]}
              x={5}
              y={height - 40 - y(firstValue)}
              rx={22}
              label={firstValue}
              value={pstring1}
              color={color}
            />
            <Rect
              triggeranimation={triggeranimation}
              delay={indexDelays[index[0]]}
              x={10}
              y={height - y(firstValue)}
              height={triggeranimation ? y(firstValue) : 0}
              fill={color}
            ></Rect>
          </G>
          <G>
            <BubbleSvg
              triggeranimation={triggeranimation}
              hasBackground={!maxIsEven}
              delay={0.5 + indexDelays[props.index[1]]}
              x={50}
              y={height - 40 - y(secondValue)}
              rx={22}
              label={secondValue}
              value={pstring2}
              color={color}
            />
            <Rect
              triggeranimation={triggeranimation}
              x={55}
              delay={indexDelays[index[1]]}
              height={triggeranimation ? y(secondValue) : 0}
              y={height - y(secondValue)}
              fill={color}
            ></Rect>
          </G>
        </Svg>
        <svg viewBox="0 0 100 10">
          <CaptionForeignObject
            x="10"
            y="0"
            width="35"
            height="20"
            color={color}
            dangerouslySetInnerHTML={createMarkup(captions[0])}
          ></CaptionForeignObject>
          <CaptionForeignObject
            x="55"
            y="0"
            width="50"
            height="25"
            color={color}
            dangerouslySetInnerHTML={createMarkup(captions[1])}
          ></CaptionForeignObject>
        </svg>
      </Wrapper>
    </Col>
  );
};

DoubleBarSvg.defaultProps = {
  captions: ["stapeltext", "stapeltext"],
  title: "Titel",
  desc: "Desc",
  pstring1: "0",
  pstring2: "0",
  maxOffset: 0,
  maxIsEven: true,
  height: "190",
  width: "100"
};

export default DoubleBarSvg;
