import React from "react";
import styled, { keyframes } from "styled-components";
import SingleBarSvg from "./SingleBarSvg";

const Wrapper = styled.div`
  padding-right: 40px;
`;

// Bubblorna ska vara på den kategori som genererat högst värde av alla fyra
// fixa ett höjd beroende max värde över 50 som läggs in dynamiskt
const DoubleBarSvg = props => {
  const indexDelays = [0, 0.2, 0.35, 0.45];

  const titleId = props.ariaId ? props.ariaId + "Title" : "";
  const descId = props.ariaId ? props.ariaId + "Desc" : "";

  const {
    captions,
    title,
    desc,
    color,
    firstValue,
    secondValue,
    triggerAnimation,
    height,
    width,
    topSpace,
    maxOffset,
    maxIsEven,
    pstring1,
    pstring2
  } = props;

  return (
    <Wrapper className="d-flex justify-content-start">
      <SingleBarSvg
        height={height}
        width={width}
        topSpace={topSpace}
        titleId={titleId}
        desc={desc}
        descId={descId}
        caption={captions[0]}
        value={firstValue}
        percentageString={pstring1}
        delay={indexDelays[props.index[0]]}
        triggerAnimation={triggerAnimation}
        maxOffset={maxOffset}
        hasBubbleBg={maxIsEven}
        color={color}
        lineType={"strait-double"}
      ></SingleBarSvg>
      <SingleBarSvg
        height={height}
        width={width}
        topSpace={topSpace}
        titleId={titleId}
        desc={desc}
        descId={descId}
        caption={captions[1]}
        value={secondValue}
        percentageString={pstring2}
        delay={indexDelays[props.index[1]]}
        triggerAnimation={triggerAnimation}
        maxOffset={maxOffset}
        hasBubbleBg={!maxIsEven}
        color={color}
        lineType={"strait-double"}
      ></SingleBarSvg>
    </Wrapper>
  );
};

DoubleBarSvg.defaultProps = {
  captions: ["stapeltext", "stapeltext"],
  title: "Titel",
  desc: "Desc",
  pstring1: "0",
  pstring2: "0",
  maxOffset: 0,
  maxIsEven: true,
  height: "190",
  width: "100"
};

export default DoubleBarSvg;
