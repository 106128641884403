import React from "react";
import styled, { keyframes } from "styled-components";
import { Col } from "react-bootstrap";
import { HeadingMunicipality, roboto } from "../../Util/StyleProps";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ArrowUpward } from "@material-ui/icons";
import { device } from "../../Util/Breakpoints";
import { secondaryColor, primaryColor } from "../../Util/Colors";

const Link = styled.a`
  height: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 24px;
  font-family: ${roboto};
  font-size:12px;
  color: ${secondaryColor};

  @media${device.mobileM}{
    font-size:14px;
  }

  &:active {
    color: ${secondaryColor};
  }
  &:hover {
    color: ${secondaryColor};
    text-decoration:underline;
  }
  &:click {
    color: ${secondaryColor};
    opacity:0.82;
    text-decoration:underline;
  }
`;

const EmptySpace = props => {
  const { color, scroll } = props;

  return (
    <Col style={{ height: "100%" }}>
      <HeadingMunicipality color={color} tabIndex="-1">
        (Kommun)
      </HeadingMunicipality>
      <Link
        href="#"
        onClick={scroll ? scroll("municipalitySelector") : null}
        aria-label="Välj kommun att jämföra med"
      >
        <div className="text-center">
          <SvgIcon
            style={{ padding: 3 }}
            style={{ fontSize: "80px" }}
            component={ArrowUpward}
          ></SvgIcon>
          <div>Välj kommun att jämföra med</div>
        </div>
      </Link>
    </Col>
  );
};
EmptySpace.defaultProps = {
  scroll: null
};

export default EmptySpace;
