import React from "react";
import PropTypes from "prop-types";
import Pattern from "./../Elements/Pattern";
import styled, { keyframes } from "styled-components";
import { useAnimationFrame } from "./../../Providers/AnimationFrame";

const Path = styled.path``;

const PieChart = props => {
  const offset = 0;
  const {
    width,
    bgRotation,
    data,
    desc,
    title,
    ariaId,
    blockColor,
    triggerAnimation
  } = props;

  const hasBg = data.find(item => item.background);
  const background = hasBg ? hasBg.background : "defaultId";
  const titleId = ariaId ? ariaId + "Title" : "";
  const descId = ariaId ? ariaId + "Desc" : "";

  return (
    <svg
      viewBox={`0 0 ${width} ${width}`}
      aria-labelledby={titleId + " " + descId}
    >
      <title id={titleId}>{title}</title>
      <desc id={descId}>{desc}</desc>
      <defs>
        <Pattern width={100} rotation={bgRotation} patternId={background} />
      </defs>
      <g transform={`translate(${offset}, ${offset})`}>
        <Arcs
          center={width / 2}
          data={data}
          strokeWidth={0}
          strokeColor={"#444444"}
          triggerAnimation={triggerAnimation}
        />
        <circle cx="50" cy="50" r="20" fill={blockColor} />
      </g>
    </svg>
  );
};

const Arcs = props => {
  const {
    center,
    data,
    strokeWidth,
    strokeColor,
    strokeLinejoin,
    transitionTimingFunction,
    transitionDuration,
    triggerAnimation
  } = props;

  const total = Math.ceil(
    data.reduce((prev, current) => current.value + prev, 0)
  );
  let angleStart = 0;
  let angleEnd = 0;
  const animationFrame = useAnimationFrame(triggerAnimation);

  return (
    <g>
      {data.map((d, i) => {
        const isLarge = (d.value * animationFrame) / total > 0.5;
        const angle = (360 * (d.value * animationFrame)) / total;
        const radius = center - strokeWidth / 2;
        angleStart = angleEnd;
        angleEnd = angleStart + angle;

        const x1 = center + radius * Math.cos((Math.PI * angleStart) / 180);
        const y1 = center + radius * Math.sin((Math.PI * angleStart) / 180);
        const x2 = center + radius * Math.cos((Math.PI * angleEnd) / 180);
        const y2 = center + radius * Math.sin((Math.PI * angleEnd) / 180);
        const pathdata = `
          M${center},${center}
          L${x1},${y1}
          A${radius},${radius}
          0 ${isLarge ? 1 : 0},1
          ${x2},${y2}
          z
        `;

        return (
          <Path
            key={"sector" + i}
            transform={"rotate(-90 50 50)"}
            d={pathdata}
            fill={d.background ? `url(#${d.background})` : d.color}
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinejoin={strokeLinejoin}
          ></Path>
        );
      })}
    </g>
  );
};

export default PieChart;
