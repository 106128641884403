import React from "react";
import styled, { keyframes } from "styled-components";
// import { fadeInUp } from "react-animations";
// import { useInView } from "react-intersection-observer";
import { device } from "../../Util/Breakpoints";
import { periodToComma, createMarkup } from "../../Helpers/CommonHelper";
import {
  drukText,
  roboto,
  fontSizeSmall,
  letterSpacingDruk,
  scaleInFigure
} from "../../Util/StyleProps";

// const fadeIn = keyframes`${fadeInUp}`;

const Wrapper = styled.div`
  color: ${props => props.color};
  .MuiSvgIcon-root {
    background: #ffffff;
    border-radius: 50%;
  }
`;

const Span = styled.span`
  font-size: ${fontSizeSmall}
  font-family: ${roboto};
  line-height: 18px;
  margin-top: -10px;
  display: block;
  margin-bottom: 20px;
`;

const Figure = styled.h3`
  font-size: 40px;
  @media${device.mobileL} {
    font-size: ${props => props.fontSize};
  }
  font-family: ${drukText};
  letter-spacing: ${letterSpacingDruk};
  margin-top: 5px;
  first-child: nth-last-child(n + 3);
  animation: 2s ${props => (props.triggerAnimation ? scaleInFigure : "")} 0.5s
    cubic-bezier(0.175, 0.885, 0.275, 1.235);
  -webkit-animation: ${props => (props.triggerAnimation ? scaleInFigure : "")}
    0.5s cubic-bezier(0.175, 0.885, 0.275, 1.235);
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;

  .smallPercent {
    font-size: 40px;
  }
`;

const KeyFigureBig = props => {
  const {
    heading,
    value,
    color,
    triggerAnimation,
    delay,
    size,
    unit,
    subText
  } = props;

  let percent = "<span>%</span>";

  if (value.length > 2) {
    if (value[1] != ",") {
      percent = '<span class="smallPercent">%</span>';
    }
  }
  return (
    <Wrapper color={color}>
      <div>
        <div>
          <span color={color}>{heading}</span>
        </div>
        <div>
          <Figure
            triggerAnimation={triggerAnimation}
            delay={delay}
            fontSize={size}
          >
            {periodToComma(value)}
            {unit == "%" ? (
              <span dangerouslySetInnerHTML={createMarkup(percent)} />
            ) : (
              unit
            )}
          </Figure>
        </div>
        <div>
          <Span>{subText}</Span>
        </div>
      </div>
    </Wrapper>
  );
};

export default KeyFigureBig;
