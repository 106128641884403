import React from "react";
import BlockLayout from "./../Layout/BlockLayout";
import ComponentHeader from "../Header/ComponentHeader";
import KeyFigureNew from "../Elements/KeyFigureNew";
import { useInView } from "react-intersection-observer";
import {
  HeadingMunicipality,
  headingSmall,
  Line,
  FiguresWrapper
} from "../../Util/StyleProps";
import { primaryColor, secondaryColor } from "../../Util/Colors";

import {
  calculatePercentage,
  isCurrentYear,
  periodToComma
} from "../../Helpers/CommonHelper";
import SvgLayout from "./../Layout/SvgLayout";
import EmptySpace from "../Elements/EmptySpace";

const LivingConditionContainer = props => {
  const { data, sweden, blockColor, scroll } = props;
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  let municipality1 = data[0];
  let municipality2 = data[1];

  let cohabitingPercentage = 0;
  let singlePercentage = 0;
  let otherPercentage = 0;

  if (sweden.livingcondition) {
    cohabitingPercentage = calculatePercentage(
      sweden.livingcondition.cohabiting,
      sweden.livingcondition.total
    );

    singlePercentage = calculatePercentage(
      sweden.livingcondition.single,
      sweden.livingcondition.total
    );

    otherPercentage = calculatePercentage(
      sweden.livingcondition.other,
      sweden.livingcondition.total
    );
  }
  let subText = ` I Sverige är ${periodToComma(
    cohabitingPercentage
  )} procent sammanboende (med eller utan barn). ${periodToComma(
    singlePercentage
  )} procent ensamstående 
  (med eller utan barn). ${periodToComma(
    otherPercentage
  )} procent tillhör någon annan typ av hushåll (${
    sweden.livingcondition ? sweden.livingcondition.year : ""
  }).`;

  // Accessibility
  const ariaLabel = municipality => {
    const str = municipality
      ? isCurrentYear(municipality.year)
        ? "är"
        : "var"
      : "";

    return municipality
      ? `I ${municipality.name} kommun ${str} ${calculatePercentage(
          municipality.cohabiting,
          municipality.total
        )} procent sammanboende (med eller utan barn) år ${
          municipality.year
        }. ${calculatePercentage(
          municipality.single,
          municipality.total
        )} procent ${str} ensamstående (med eller utan barn)
        och ${calculatePercentage(
          municipality.other,
          municipality.total
        )} procent tillhör någon annan typ av hushåll.`
      : "";
  };

  return (
    <BlockLayout background={blockColor}>
      <ComponentHeader heading={"Så ser våra hushåll ut"} text={subText} />
      {municipality1 ? (
        <div ref={ref}>
          <FiguresWrapper maxWidth="1000px">
            {municipality1 ? (
              <SvgLayout width="50%">
                <HeadingMunicipality
                  color={primaryColor}
                  aria-label={ariaLabel(municipality1)}
                  marginBottom="16px"
                >
                  {municipality1.name}
                </HeadingMunicipality>

                <KeyFigureNew
                  triggerAnimation={inView}
                  delay={0.75}
                  iconName={"person"}
                  alt="Ikon person"
                  color={primaryColor}
                  size={headingSmall}
                  value={calculatePercentage(
                    municipality1.single,
                    municipality1.total
                  )}
                  subText={"ensamstående"}
                  unit={"%"}
                />
                <KeyFigureNew
                  triggerAnimation={inView}
                  delay={0.75}
                  iconName={"people"}
                  alt="Ikon personer"
                  color={primaryColor}
                  size={headingSmall}
                  value={calculatePercentage(
                    municipality1.cohabiting,
                    municipality1.total
                  )}
                  subText={"sammanboende"}
                  unit={"%"}
                />
                <KeyFigureNew
                  triggerAnimation={inView}
                  delay={0.75}
                  iconName={"person-add"}
                  alt="Person lägg till"
                  color={primaryColor}
                  size={headingSmall}
                  value={calculatePercentage(
                    municipality1.other,
                    municipality1.total
                  )}
                  subText={"övriga hushåll"}
                  unit={"%"}
                />
              </SvgLayout>
            ) : (
              <div></div>
            )}
            <Line marginBottom="36px"></Line>
            {municipality2 ? (
              <SvgLayout width="50%" marginLeft="30px">
                <HeadingMunicipality
                  color={secondaryColor}
                  aria-label={ariaLabel(municipality2)}
                  marginBottom="16px"
                >
                  {municipality2.name}
                </HeadingMunicipality>

                <KeyFigureNew
                  triggerAnimation={inView}
                  delay={1.75}
                  iconName={"person"}
                  alt="Ikon person"
                  color={secondaryColor}
                  size={headingSmall}
                  value={calculatePercentage(
                    municipality2.single,
                    municipality2.total
                  )}
                  subText={"ensamstående"}
                  unit={"%"}
                />
                <KeyFigureNew
                  triggerAnimation={inView}
                  delay={1.75}
                  iconName={"people"}
                  alt="Ikon personer"
                  color={secondaryColor}
                  size={headingSmall}
                  value={calculatePercentage(
                    municipality2.cohabiting,
                    municipality2.total
                  )}
                  subText={"sammanboende"}
                  unit={"%"}
                />
                <KeyFigureNew
                  triggerAnimation={inView}
                  delay={1.75}
                  iconName={"person-add"}
                  alt="Person lägg till"
                  color={secondaryColor}
                  size={headingSmall}
                  value={calculatePercentage(
                    municipality2.other,
                    municipality2.total
                  )}
                  subText={"övriga hushåll"}
                  unit={"%"}
                />
              </SvgLayout>
            ) : (
              <SvgLayout width="50%">
                <EmptySpace scroll={scroll} />
              </SvgLayout>
            )}
          </FiguresWrapper>
        </div>
      ) : null}
    </BlockLayout>
  );
};

export default LivingConditionContainer;
