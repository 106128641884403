import React, { useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import styled from "styled-components";
import KeyFigureNew from "../Elements/KeyFigureNew";
import BubbleFlexSvg from "../Elements/BubbleFlexSvg";
import BlockLayout from "./../Layout/BlockLayout";
import SvgLayout from "./../Layout/SvgLayout";
import { createMarkup } from "../../Helpers/CommonHelper";
import {
  headingSmall,
  Heading2,
  Heading6,
  HeadingMunicipality,
  Line,
  FiguresWrapper
} from "../../Util/StyleProps";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import { useInView } from "react-intersection-observer";
import {
  Sign,
  ArrowValue,
  numberWithSpaces,
  isCurrentYear
} from "../../Helpers/CommonHelper";
import EmptySpace from "../Elements/EmptySpace";

const PopulationChange = props => {
  const { sweden, populationchangedata, data } = props;
  var xaxissize = "";
  const [ref, inView, entry] = useInView({
    /* Optional options*/
    threshold: 0.2,
    triggerOnce: true
  });

  let year = populationchangedata[0] ? populationchangedata[0].year : "";
  const lastYear = sweden.population.values.slice(-1)[0];
  const beforeYear = sweden.population.values.slice(-2)[0];
  const popDiff = lastYear.population - beforeYear.population;

  let municipality1 = data[0];
  let municipality2 = data[1];
  let municipality1Change = populationchangedata[0];
  let municipality2Change = populationchangedata[1];

  const noWrapPopulation =
    "<span class='text-nowrap'>" +
    numberWithSpaces(lastYear.population) +
    "</span>";

  const noWrapDiff =
    "<span class='text-nowrap'>" + numberWithSpaces(popDiff) + "</span>";

  let subText = `I slutet av ${
    lastYear ? lastYear.year : "(årtal saknas)"
  } var Sveriges folkmängd ${
    lastYear ? noWrapPopulation : "(befolkningsmängd ej tillgänglig)"
  } personer.`;

  var subTextKeyFig = "personer";

  const ariaLabelPopChange = municipalityChange => {
    return municipalityChange
      ? `Befolkningen i ${municipalityChange.name} kommun ${
          Math.sign(municipalityChange.value) === 0
            ? isCurrentYear(municipalityChange.year)
              ? "är"
              : "var"
            : Math.sign(municipalityChange.value) > 0
            ? "ökade med"
            : "minskade med"
        } ${Math.abs(municipalityChange.value)} personer (år ${
          municipalityChange.year
        })`
      : "";
  };

  return (
    <React.Fragment>
      <Heading2 tabIndex="0">Så har befolkningen förändrats</Heading2>
      <Heading6
        tabIndex="0"
        dangerouslySetInnerHTML={createMarkup(
          `Sveriges befolkning ökade ${lastYear.year} med ${noWrapDiff} personer.`
        )}
      />
      <div ref={ref}>
        <FiguresWrapper marginTop="24px">
          {municipality1Change ? (
            <SvgLayout width="50%">
              <HeadingMunicipality
                aria-label={ariaLabelPopChange(municipality1Change, year)}
                marginBottom="12px"
              >
                {municipality1.name}
              </HeadingMunicipality>
              <KeyFigureNew
                iconName={ArrowValue(
                  municipality1Change ? municipality1Change.value : 0
                )}
                triggerAnimation={inView}
                color={primaryColor}
                size={headingSmall}
                delay={0.75}
                value={Sign(
                  municipality1Change ? municipality1Change.value : 0
                )}
                subText={subTextKeyFig}
              />
            </SvgLayout>
          ) : (
            <div></div>
          )}
          <Line marginBottom="30px" />
          {municipality2Change ? (
            <SvgLayout width="50%" marginLeft="30px">
              <HeadingMunicipality
                aria-label={ariaLabelPopChange(municipality2Change, year)}
                marginBottom="12px"
              >
                {municipality2.name}
              </HeadingMunicipality>
              <KeyFigureNew
                iconName={ArrowValue(
                  municipality2Change ? municipality2Change.value : 0
                )}
                triggerAnimation={inView}
                color={secondaryColor}
                size={headingSmall}
                delay={0.75}
                value={Sign(
                  municipality2Change ? municipality2Change.value : 0
                )}
                subText={subTextKeyFig}
              />
            </SvgLayout>
          ) : (
            <SvgLayout width="50%">
              <EmptySpace />
            </SvgLayout>
          )}
        </FiguresWrapper>
      </div>
    </React.Fragment>
  );
};

export default PopulationChange;
