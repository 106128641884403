import React from "react";
import Highcharts from "highcharts";
import {
  HighchartsProvider,
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Legend,
  LineSeries,
  Series,
  Tooltip
} from "react-jsx-highcharts";

const HighChartLineChart = props => {
  var lines = [];
  const { data, startpoint, triggerAnimation } = props;

  if (data[0] || data[1]) {
    for (var key in data) {
      if (data.hasOwnProperty(key) && data[key] !== null) {
        {
          let color = key == 0 ? "#1E00BE" : "#222222";
          let currentpopulation = data[key].values.slice(-1).pop();

          let dotted = key == 0 ? "" : "shortdot";

          let line = [
            [...data[key].values],
            color,
            data[key].name,
            currentpopulation,
            dotted
          ];
          lines.push(line);
        }
      }
    }
  }

  Highcharts.setOptions({
    lang: {
      numericSymbols: [null]
    },
    chart: {
      borderColor: "",
      plotBorderWidth: 1,
      plotBorderColor: "",
      plotShadow: false,
      type: "line"
    },
    xAxis: {
      lineColor: "#1E00BE",
      visible: true,
      lineWidth: 2,
      labels: {
        style: {
          color: "#1E00BE",
          fontFamily: "Roboto",
          fontSize: "14px"
        }
      },
      type: "year",
      tickLength: 1,
      startOnTick: false,
      endOnTick: true,
      /*  labels: {
        formatter: function() {
          // return "2018";
        }
      },*/
      showFirstLabel: true,
      showLastLabel: true
    },
    yAxis: {
      visible: true,
      min: 0,
      gridLineColor: "none",
      labels: {
        style: {
          color: "#1E00BE",
          fontFamily: "Roboto",
          fontSize: "14px"
        }
      },
      showFirstLabel: true,
      showLastLabel: true
    },
    plotOptions: {
      series: {
        lineWidth: 4,
        shadow: true,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 4,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    }
  });
  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsChart>
        <Chart />
        <XAxis>
          {lines.map((linedata, index) => {
            if(triggerAnimation){
              return (
                <Series
                  key={index}
                  name={linedata[2]}
                  data={linedata[0]}
                  color={linedata[1]}
                  dashStyle={linedata[4]}
                  pointStart={startpoint}
                >
                  <Tooltip />
                </Series>
              );

            } else {
            return (
              null
            )}
          })}
        </XAxis>
        <YAxis>
          <Legend align="center" />
        </YAxis>
      </HighchartsChart>
    </HighchartsProvider>
  );
};

export default HighChartLineChart;
