import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import styled from "styled-components";
import logoSvg from "../../assets/img/scb_logo_svart.svg";
import logoPng from "../../assets/img/SCB-logo_sv.png";
import Menu from "@material-ui/icons/Menu";
import { device } from "../../Util/Breakpoints";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import { roboto, fontSizeMedium, maxWidthBlock } from "./../../Util/StyleProps";
import { primaryColor } from "./../../Util/Colors";

const Wrapper = styled.div`
  margin:0 auto;
  max-width: ${maxWidthBlock};
  position:relative; 
  padding:0 24px;

  @media${device.mobileL}{
    padding: 0 36px;
  }
  @media${device.tablet}{
    padding: 0 48px;
  }

  .navbar {
    height:58px;
    padding:0;
  }

  .navbar-brand {
    padding:0;
  }
  
  .scbLink {
    font-family: ${roboto};
    font-weight: 500;
    font-size: ${fontSizeMedium}
    color:${primaryColor}
    line-height: 20px;
    position:absolute;
    right:0; 
    padding:0;
  }

  .scbLink:after {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0px;
    border-width: 0 0 1px;
    color:${primaryColor};
    border-style: solid;
    opacity:0.47;
}

`;

const Logo = styled.img`
  width: 36px;
`;

const MainNav = () => {
  const [showSearch, toggleSearch] = useState(false);

  return (
    <Wrapper>
      <Navbar expand="md">
        <Navbar.Brand href="http://www.scb.se" aria-label="Till scb.se">
          <Logo src={logoSvg} alt="Statistika Centralbyrån" />
        </Navbar.Brand>
        <Nav.Link href="http://www.scb.se" className="scbLink">
          Till scb.se
        </Nav.Link>
      </Navbar>
    </Wrapper>
  );
};
export default MainNav;
