import React from "react";
import styled from "styled-components";
import BlockLayout from "./../Layout/BlockLayout";
import KeyFigureLink from "./../Elements/KeyFigureLink";
import { getBrowserQueryParams } from "../../Helpers/CommonHelper";
import { getMunicipalityName, hasMunicipalityName } from "../../data/kommuner";


const EndWrapper = styled.div`
  background: #fff;
  width: 100%;
  min-height: 174px;
`;

const Navigation = styled.nav``;

const EndComponent = props => {
  const { scroll } = props;

  return (
    <EndWrapper>
      <BlockLayout
        paddingTop="30px"
        paddingBottom="36px"
        backgroundColor="#fff"
      >
        <Navigation>
          <KeyFigureLink
            iconName={"place"}
            href="#"
            color="#fff"
            onClickFunction={scroll}
          >
            Välj nya kommuner
          </KeyFigureLink>
          <KeyFigureLink
            iconName={"table-chart-outline"}
            href={getMuniciplitiesInNumbersURL()}
            color="#fff"
          >
            Se mer statistik om Sveriges kommuner
          </KeyFigureLink>
          <KeyFigureLink
            iconName={"info-outline"}
            href={"https://scb.se/om_kommunsiffror"}
            color="#fff"
          >
            Läs om statistiken
          </KeyFigureLink>
        </Navigation>
      </BlockLayout>
    </EndWrapper>
  );
};
EndComponent.defaultProps = {
  scroll: null
};
export default EndComponent;


function getRequestedMunicipalities() {
  const bp = getBrowserQueryParams();
  let munObj = { "1": null, "2": null };

  if (bp.id1 && bp.id1[0] && hasMunicipalityName(bp.id1[0])) {
    munObj["1"] = {
      id: bp.id1[0],
      name: getMunicipalityName(bp.id1[0])
    };
  }

  if (bp.id2 && bp.id2[0] && hasMunicipalityName(bp.id2[0])) {
    munObj["2"] = {
      id: bp.id2[0],
      name: getMunicipalityName(bp.id2[0])
    };
  }

  return munObj;
}

const getMuniciplitiesInNumbersURL = () => {
  const params = getRequestedMunicipalities();

   const id1 = params['1'] ? params['1'].id : null;
   const id2 = params['2'] ? params['2'].id : null;

  const baseUrl =
    "https://www.scb.se/hitta-statistik/sverige-i-siffror/kommuner-i-siffror/#";
  const url = id1 || id2 ? `${baseUrl}?region1=${id1}&region2=${id2}` : baseUrl;
  return url;
};
