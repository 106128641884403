import React from "react";
import styled, { keyframes } from "styled-components";
import { fadeIn, slideInUp } from "react-animations";
import { drukText, roboto, letterSpacingDruk, scaleInFigure } from "../../Util/StyleProps";
import { numberWithSpaces } from "../../Helpers/CommonHelper";
import LinePath from "./LinePathSvg";

const fadeInAnimation = keyframes`${fadeIn}`;

const G = styled.g``;

const BubbleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BarCircleValue = styled.foreignObject`
  // min-width: ${props => (props.width ? props.width : "100px")};
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "100px")};
  fill: ${props => props.color};
  font-family: ${drukText};
  line-height: 33px;
  animation: 2s ${props => (props.triggerAnimation ? scaleInFigure : "")} 0.5s
    cubic-bezier(0.175, 0.885, 0.275, 1.035);
  -webkit-animation: ${props => (props.triggerAnimation ? scaleInFigure : "")}
    0.5s cubic-bezier(0.175, 0.885, 0.275, 1.035);
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
  opacity: 0;
`;

const Bubble = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: ${props =>
    props.width ? `${parseInt(props.width, 10) * 0.7}px` : "100px"};
  color: ${props => props.color};
  background-color: ${props => (props.hasBackground ? "#fff" : "transparent")};
  border-radius: 99px;
  padding: ${props => (props.padding ? props.padding : "9px 10px 6px")};
  text-align: center;
`;

const ValueText = styled.span`
  font-size: ${props => (props.setFontSize ? props.setFontSize : "18px")}
  letter-spacing: ${letterSpacingDruk};
  line-height: 100%;
  padding: ${props => (props.padding ? props.padding : 0)};
  color: ${props => props.color};
`;

const LabelBottom = styled.span`
  font-family: ${roboto};
  font-size: ${props => (props.setFontSize ? props.setFontSize : "18px")}
  line-height: ${props => (props.lineHeight ? props.lineHeight  : "12px")}
  color: ${props => props.color};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : 0)};
`;


const BubbleFlexSvg = props => {
  const {
    x,
    y,
    value,
    label,
    color,
    delay,
    triggerAnimation,
    hasBackground,
    padding,
    hasLine,
    lineType,
    isPercent,
    width,
    viewBoxWidth, // this affects the width the bubble inside  - the larger the viewBoxWidth the smaller the bubble
    viewBoxHeight,
    height
  } = props;

  const hasComma = toString(value).includes(",");

  return (
    <g>
      <BarCircleValue
        triggerAnimation={triggerAnimation}
        color={color}
        delay={delay}
        width={width}
        height={height}
        y={y}
        x={x - 10}
        width={viewBoxWidth + "px"}
      >
        <BubbleContainer>
          <Bubble hasBackground={hasBackground} width={width} padding={value.length < 7 ? "9px 10px 6px" : "7px 8px 5px"}>
            <ValueText
              padding={value.length < 6 || isPercent ? "0 7px" : 0}
              setFontSize={value.length < 6 ? "22px" : "18px"}
            >
              {numberWithSpaces(value)}
              {isPercent ? "%" : ""}
            </ValueText>
            <LabelBottom
              paddingTop={hasComma ? "2px" : 0}
              setFontSize={value.length < 7 ? "8px" : "6px"}
              lineHeight={value.length < 7 ? "12px" : "8px"}
            >
              {label}
            </LabelBottom>
          </Bubble>
        </BubbleContainer>
      </BarCircleValue>
      {lineType ? (
        <LinePath color={color} lineType={lineType} x={x + 50} y={y + 53} />
      ) : null}
    </g>
  );
};
BubbleFlexSvg.defaultProps = {
  x: 0,
  y: 0,
  width: "100px",
  height: "100px",
  viewBoxWidth: 100,
  viewBoxHeight: 120,
  lineType: null,
  value: 0,
  label: "Label",
  isPercent: false,
  hasBackground: false,
  triggerAnimation: false,
  delay: 0,
  hasLine: false
};

export default BubbleFlexSvg;
