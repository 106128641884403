import React from "react";
import { Image } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";

const SectionImageBetween = props => {
  const { alt } = props;

  return (
    <Container
      fluid
      style={{
        background:
          "linear-gradient(to bottom, " +
          props.topcolor +
          "  50% , " +
          props.bottomcolor +
          " 50%",
        margin: 0
      }}
    >
      <Row className=" justify-content-center">
        <Col xs={6} className="text-center">
          <Image src={props.image} fluid alt={alt} />
        </Col>
      </Row>
    </Container>
  );
};

SectionImageBetween.defaultProps = {
  alt: "Bildbeskrivning finns ej"
};
export default SectionImageBetween;
