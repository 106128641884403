//Component Colors
export const primaryColor = "#1E00BE";
export const secondaryColor = "#222222";
export const sectionPopulationColor = "#FFD478";
export const sectionBirthplaceColor = "#FFF0B9";
export const sectionLandSizeColor = "#87CD78";
export const sectionGenderColor = "#FFF0B9";
export const sectionPopulationLocationColor = "#CDF0B4";
export const sectionAccommodationColor = "#87CD78";
export const sectionLivingConditionColor = "#CDF0B4";
export const sectionResidenceColor = "#CDF0B4";
export const sectionIncomeColor = "#DE82DC";
export const sectionEmplomentExtentColor = "#F0C3E6";
export const sectionTaxColor = "#FF8C69";
export const sectionElectionColor = "#FFCDB9";
export const lineColor = "#949494";
export const black = "#151515";
export const messageBoxBackground = "#F8F7F6";
