import React from "react";
import BlockLayout from "./../Layout/BlockLayout";
import SvgLayout from "./../Layout/SvgLayout";
import ComponentHeader from "../Header/ComponentHeader";
import { primaryColor, secondaryColor } from "../../Util/Colors";
import { calculatePercentage } from "../../Helpers/CommonHelper";
import { useInView } from "react-intersection-observer";
import KeyFigureNew from "../Elements/KeyFigureNew";
import EmptySpace from "../Elements/EmptySpace";
import { periodToComma } from "../../Helpers/CommonHelper";
import {
  headingSmall,
  headingLarge,
  HeadingMunicipality,
  Line,
  FiguresWrapper
} from "../../Util/StyleProps";

import KeyFigureBig from "../Elements/KeyFigureBig";

const EmplomentExtentContainer = props => {
  const { data, sweden, blockColor, scroll } = props;

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  const lastYear = sweden.emplomentextent.year;
  const swedenMedium = calculatePercentage(
    sweden.emplomentextent.men_and_women_in_work,
    sweden.emplomentextent.total
  );

  const swedenWomen = calculatePercentage(
    sweden.emplomentextent.women_in_work,
    sweden.emplomentextent.women_in_work +
      sweden.emplomentextent.women_not_working
  );

  const swedenMen = calculatePercentage(
    sweden.emplomentextent.men_in_work,
    sweden.emplomentextent.men_in_work + sweden.emplomentextent.men_not_working
  );
  // I Sverige har 79,1 procent av dem som är 16-65 år ett jobb. Det är 77,9 procent av kvinnorna som jobbar, och 80,3 procent av männen (2018).
  //  Så många har jobb
  //  I Sverige har 79,5 procent av dem som är 20-64 år jobb. 78,1 procent av kvinnorna har jobb och 80,8 av männen (2018).

  //  Och under den översta kommunala procenten ändrar vi till:
  //  har jobb

  const swedenString =
    "I Sverige har " +
    periodToComma(swedenMedium) +
    " procent av dem som är 20-64 år jobb. " +
    periodToComma(swedenWomen) +
    " procent av kvinnorna har jobb och " +
    periodToComma(swedenMen) +
    " av männen (" +
    lastYear +
    ").";

  let municipality1 = data[0];
  let municipality2 = data[1];
  // Accessibility
  const ariaLabel = municipality => {
    return municipality
      ? `I ${municipality.name} kommun har ${calculatePercentage(
          municipality.women_in_work + municipality.men_in_work,
          municipality.total
        )} procent ett jobb. ${calculatePercentage(
          municipality.women_in_work,
          municipality.women_in_work + municipality.women_not_working
        )} procent av kvinnorna och ${calculatePercentage(
          municipality.men_in_work,
          municipality.men_in_work + municipality.men_not_working
        )} procent av männen (${
          municipality.year ? "år" + municipality.year : "årtal saknas"
        }).`
      : "";
  };

  return (
    <React.Fragment>
      <BlockLayout background={blockColor}>
        <ComponentHeader heading={"Så många har jobb"} text={swedenString} />
        {municipality1 ? (
          <div ref={ref}>
            <FiguresWrapper maxWidth="1000px">
              {municipality1 ? (
                <SvgLayout width="50%">
                  <HeadingMunicipality
                    color={primaryColor}
                    aria-label={ariaLabel(municipality1)}
                  >
                    {municipality1.name}
                  </HeadingMunicipality>
                  <KeyFigureBig
                    triggerAnimation={inView}
                    delay={0.75}
                    color={primaryColor}
                    size={headingLarge}
                    value={calculatePercentage(
                      municipality1.women_in_work + municipality1.men_in_work,
                      municipality1.total
                    )}
                    subText={"har jobb"}
                    unit={"%"}
                  />
                  <KeyFigureNew
                    triggerAnimation={inView}
                    delay={0.75}
                    iconName={"female"}
                    alt="Ikon kvinna"
                    color={primaryColor}
                    size={headingSmall}
                    value={calculatePercentage(
                      municipality1.women_in_work,
                      municipality1.women_in_work +
                        municipality1.women_not_working
                    )}
                    subText={"av kvinnor"}
                    unit={"%"}
                  />
                  <KeyFigureNew
                    triggerAnimation={inView}
                    delay={0.75}
                    iconName={"male"}
                    alt="Ikon man"
                    color={primaryColor}
                    size={headingSmall}
                    value={calculatePercentage(
                      municipality1.men_in_work,
                      municipality1.men_in_work + municipality1.men_not_working
                    )}
                    subText={"av män"}
                    unit={"%"}
                  />
                </SvgLayout>
              ) : (
                <div></div>
              )}

              <Line marginBottom="36px" />
              {municipality2 ? (
                <SvgLayout width="50%" marginLeft="30px">
                  <HeadingMunicipality
                    color={secondaryColor}
                    aria-label={ariaLabel(municipality2)}
                  >
                    {municipality2.name}
                  </HeadingMunicipality>
                  <KeyFigureBig
                    triggerAnimation={inView}
                    delay={1.75}
                    color={secondaryColor}
                    size={headingLarge}
                    value={calculatePercentage(
                      municipality2.men_in_work + municipality2.women_in_work,
                      municipality2.total
                    )}
                    subText={"har jobb"}
                    unit={"%"}
                  />
                  <KeyFigureNew
                    triggerAnimation={inView}
                    delay={1.75}
                    iconName={"female"}
                    alt="Ikon kvinna"
                    color={secondaryColor}
                    size={headingSmall}
                    value={calculatePercentage(
                      municipality2.women_in_work,
                      municipality2.women_in_work +
                        municipality2.women_not_working
                    )}
                    subText={"av kvinnor"}
                    unit={"%"}
                  />
                  <KeyFigureNew
                    triggerAnimation={inView}
                    delay={1.75}
                    iconName={"male"}
                    alt="Ikon man"
                    color={secondaryColor}
                    size={headingSmall}
                    value={calculatePercentage(
                      municipality2.men_in_work,
                      municipality2.men_in_work + municipality2.men_not_working
                    )}
                    subText={"av män"}
                    unit={"%"}
                  />
                </SvgLayout>
              ) : (
                <SvgLayout width="50%">
                  <EmptySpace scroll={scroll} />
                </SvgLayout>
              )}
            </FiguresWrapper>
          </div>
        ) : null}
      </BlockLayout>
    </React.Fragment>
  );
};

EmplomentExtentContainer.defaultProps = {
  data: [],
  sweden: {}
};

export default EmplomentExtentContainer;
