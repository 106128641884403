import React, { useContext } from "react";


const useAnimationFrame = (trigger) => {
  const animFrameAmount = 1 / 90;
  let animCount = 0;

  const [animationFrame, setFrame] = React.useState(0);

  const animationEasing = (t) => {
    var v = t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
    return v > 1 ? 1 : v;
  };

  const animationLoop = () => {
    animCount += animFrameAmount;
    setFrame(animationEasing(animCount));
    if (animCount < 1) {
      requestAnimationFrame(animationLoop);
    } else {
      cancelAnimationFrame(animationLoop);
    }
  };

  const reverseLoop = () => {
    animCount -= animFrameAmount;
    setFrame(animationEasing(animCount));
    if (animCount > 0) {
      requestAnimationFrame(reverseLoop);
    } else {
      cancelAnimationFrame(reverseLoop);
    }
  };

  React.useEffect((a) => {
    if(trigger && animCount < 1){
      requestAnimationFrame(animationLoop);
    } 
    if(!trigger){
      cancelAnimationFrame(animationLoop);
      //animCount = 1;
      //requestAnimationFrame(reverseLoop);

      animCount = 0;
      setFrame(0);
    } 
  }, [trigger]); 

  return animationFrame;
};

export { useAnimationFrame };
