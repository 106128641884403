import React from "react";
import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";
import { useInView } from "react-intersection-observer";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  scaleInFigure,
  roboto,
  drukText,
  letterSpacingDruk
} from "../../Util/StyleProps";
import { secondaryColor } from "../../Util/Colors";

const fadeIn = keyframes`${fadeInUp}`;

const Wrapper = styled.div`
  padding-top: 15px;
  color: ${props => props.color};
  .MuiSvgIcon-root {
    background: #ffffff;
    border-radius: 50%;
  }
`;

const FigureWrapper = styled.div`
  padding-top: 5px;
  display: flex;
  justify-content: start;
`;

const IconWrapper = styled.div`
  padding-right: 15px;
`;
const Heading = styled.h6`
  font-size: 12px;
  font-family: ${roboto};
  font-weight: bold;
`;
const SubText = styled.span`
  font-size: 12px;
  font-family: ${roboto};
  line-height: 18px;
  font-weight: bold;
`;

const ValueText = styled.span`
  font-size: ${props => (props.setFontSize ? props.setFontSize : "18px")}
  line-height: 100%;
  padding: ${props => (props.padding ? props.padding : 0)};
  color: ${props => props.color};
`;

const Figure = styled.h3`
  font-size: ${props => props.fontSize};
  line-height: 18px;
  font-family: ${drukText};
  letter-spacing: ${letterSpacingDruk};
  margin-top: 5px;
  first-child: nth-last-child(n + 3);
  animation: 2s ${props => (props.triggerAnimation ? scaleInFigure : "")} 0.5s
    cubic-bezier(0.175, 0.885, 0.275, 1.235);
  -webkit-animation: ${props => (props.triggerAnimation ? scaleInFigure : "")}
    0.5s cubic-bezier(0.175, 0.885, 0.275, 1.235);
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: both;
`;

const KeyFigure = props => {
  const {
    value,
    unit,
    municipalityname,
    color,
    icon,
    subText,
    triggerAnimation,
    delay,
    isPercent,
    size
  } = props;
  return (
    <Wrapper color={color}>
      <Heading>{municipalityname}</Heading>
      <FigureWrapper>
        <IconWrapper>
          <SvgIcon component={icon} fontSize={"large"}></SvgIcon>
        </IconWrapper>
        <div>
          <Figure
            triggerAnimation={triggerAnimation}
            delay={delay}
            fontSize={size}
          >
            <ValueText
              padding={value.length < 6 || isPercent ? "0 7px" : 0}
              setFontSize={value.length < 6 ? "60px" : "60px"}
            >
              {" "}
              {value}
              {isPercent ? "%" : ""}
            </ValueText>
            {unit}
          </Figure>
          <SubText>{subText ? subText : ""}</SubText>
        </div>
      </FigureWrapper>
    </Wrapper>
  );
};

KeyFigure.defaultProps = {
  value: 0,
  unit: "",
  municipalityname: "",
  color: secondaryColor,
  subText: "",
  icon: "",
  triggerAnimation: false,
  delay: 0,
  isPercent: false,
  size: ""
};

export default KeyFigure;
