import React from "react";
import styled from "styled-components";
import Spinner from "../Spinner/Spinner";
import SpinnerSvg from "../../assets/img/spinner.svg";
import BlockLayout from "../Layout/BlockLayout";
import { sectionPopulationColor } from "./../../Util/Colors";
import { roboto } from "../../Util/StyleProps";

const SpinnerBlock = props => {
  const InfoText = styled.p`
    font-family: ${roboto};
    font-size: 24px;
    line-height: 38px;
    font-weight: 400;
    margin-top: 20px;
    text-align: center;
  `;
  return (
    <BlockLayout
      background={sectionPopulationColor}
      paddingTop="56px"
      paddingBottom="56px"
    >
      <Spinner counterClockwise={false} size="56px">
        <img src={SpinnerSvg} alt="spinner" />
      </Spinner>
      <InfoText>Nu hämtas kommunsiffor...</InfoText>
    </BlockLayout>
  );
};

export default SpinnerBlock;
