import React, { useRef } from "react";
import "./../../Styles/Dist/Bootstrap/css/bootstrap.min.css";
import "./../../Styles/Dist/fonts/Druk/druk.css";
import "./../../Styles/Dist/fonts/Roboto/roboto.css";
import styled from "styled-components";
import {
  Header,
  Footer,
  StartPage,
  BirthPlaceContainer,
  PopulationContainer,
  AgeContainer,
  GenderContainer,
  MunicipalitySelector,
  LandSizeContainer,
  LandUseContainer,
  PopulationLocationContainer,
  Api,
  IncomeContainer,
  EmplomentExtentContainer,
  AccommodationContainer,
  LivingConditionContainer,
  RecidenceContainer,
  TaxRateContainer,
  ElectionContainer,
  EndComponent
} from "./../../Components";

import startPageSvg from "../../assets/img/startPageIllustration.svg";
import populationSvg from "../../assets/img/population.svg";
import locationSvg from "../../assets/img/location.svg";
import accommodationSvg from "../../assets/img/accomodation.svg";
import walletSvg from "../../assets/img/wallet.svg";
import taxSvg from "../../assets/img/tax.svg";
import SectionImageBetween from "./../Elements/SectionImageBetween";
import SpinnerBlock from "../Spinner/SpinnerBlock";

import {
  sectionGenderColor,
  sectionPopulationColor,
  sectionLivingConditionColor,
  sectionAccommodationColor,
  sectionIncomeColor,
  sectionPopulationLocationColor,
  sectionBirthplaceColor,
  sectionTaxColor,
  sectionElectionColor,
  sectionEmplomentExtentColor,
  secondaryColor
} from "./../../Util/Colors";
import {
  isNullorEmpty,
  getBrowserQueryParams
} from "./../../Helpers/CommonHelper";
import { roboto, fontSizeMobile, lineheightSmall } from "../../Util/StyleProps";
import BlockLayout from "./BlockLayout";
import { device } from "../../Util/Breakpoints";

const AppTitle = styled.h3`
  font-family: ${roboto};
  font-size: ${fontSizeMobile};
  line-height: ${lineheightSmall};
  font-weight: 500;
  color: ${secondaryColor};
`;

const Wrapper = styled.div`
  min-height: 60vh;
  background-color: ${sectionPopulationColor};
  @media${device.desktop} {
    min-height:64vh;
  }
`;

const PageLayout = props => {
  const {
    requestedMunicipalities,
    populationdata,
    municipalityselection,
    agedata,
    montelyincomedata,
    sweden,
    landusedata,
    populationLocationdata,
    genderdata,
    accommodationdata,
    populationchangedata,
    genderAgeData,
    birthplacedata,
    emplomentextentdata,
    isLoadingData,
    livingconditiondata,
    taxratedata,
    electiondata
  } = props.data;

  // Check that all blocks contain data
  const blockArray = [
    populationdata,
    populationchangedata,
    genderdata,
    genderAgeData,
    landusedata,
    accommodationdata,
    populationLocationdata,
    montelyincomedata
  ];
  const municipalityRef = useRef(null);

  const refMunicipalitySelector = useRef(null);

  const scroll = ref => event => {
    if (ref == "municipalitySelector") {
      const params = Object.values(getBrowserQueryParams()).flat();
      event.preventDefault(); // prevent reload
      let index = params.indexOf("null");

      // Scrolls to the municipality selector ref
      municipalityRef.current.scrollIntoView({ behavior: "smooth" });

      // Focus the correct input field depending on what input that is empty
      setTimeout(function() {
        if (index == 1) {
          // Focus the second input field
          refMunicipalitySelector.current
            .querySelector("#municipality2")
            .focus();
        } else {
          // Focus the first input field
          refMunicipalitySelector.current
            .querySelector("#municipality1")
            .focus();
        }
      }, 700); // A slight delay so that the smooth scolling can finnish before focusing.
    }
  };

  let allBlocksHaveData = blocksHaveData(blockArray);

  return (
    <React.Fragment>
      <Header />
      <BlockLayout paddingTop="16px" paddingBottom="18px">
        <AppTitle tabIndex="0">Kommuner i siffror</AppTitle>
      </BlockLayout>
      <div ref={municipalityRef}>
        <MunicipalitySelector
          datahandler={props.datahandler}
          requestedMunicipalities={requestedMunicipalities}
          isLoadingData={isLoadingData}
          refMunicipalitySelector={refMunicipalitySelector}
        />
      </div>
      <Wrapper>
        {isLoadingData ? <SpinnerBlock /> : null}

        {allBlocksHaveData && !isLoadingData ? (
          <>
            {sweden.population ? (
              <>
                <PopulationContainer
                  data={populationdata.filter(data => data)}
                  populationchangedata={populationchangedata.filter(
                    data => data
                  )}
                  sweden={sweden}
                  blockColor={sectionPopulationColor}
                  scroll={scroll}
                />
                <SectionImageBetween
                  image={populationSvg}
                  alt="Illustration familj"
                  topcolor={sectionPopulationColor}
                  bottomcolor={sectionGenderColor}
                />
              </>
            ) : (
              <div></div>
            )}

            {sweden.gender ? (
              <GenderContainer
                data={genderdata.filter(data => data)}
                blockColor={sectionGenderColor}
                sweden={sweden}
                scroll={scroll}
              />
            ) : (
              <div></div>
            )}

            {sweden.age ? (
              <AgeContainer
                blockColor={sectionGenderColor}
                data={genderAgeData.filter(data => data)}
                sweden={sweden}
                scroll={scroll}
              />
            ) : (
              <div></div>
            )}

            {sweden.birthplace ? (
              <BirthPlaceContainer
                data={birthplacedata.filter(data => data)}
                blockColor={sectionBirthplaceColor}
                sweden={sweden}
                scroll={scroll}
              />
            ) : (
              <div></div>
            )}

            {sweden.populationLocation ? (
              <>
                <SectionImageBetween
                  image={locationSvg}
                  alt="Illustration jordhög med sverigeflagga"
                  topcolor={sectionGenderColor}
                  bottomcolor={sectionPopulationLocationColor}
                />
                <PopulationLocationContainer
                  data={populationLocationdata.filter(data => data)}
                  sweden={sweden}
                  scroll={scroll}
                />
              </>
            ) : (
              <div></div>
            )}

            {sweden.landuse ? (
              <LandUseContainer
                data={landusedata.filter(data => data)}
                blockColor={sectionLivingConditionColor}
                sweden={sweden}
                scroll={scroll}
              />
            ) : (
              <div></div>
            )}

            {sweden.montelyincome ? (
              <IncomeContainer
                data={montelyincomedata.filter(data => data)}
                blockColor={sectionIncomeColor}
                sweden={sweden}
                scroll={scroll}
              />
            ) : (
              <div></div>
            )}
            {emplomentextentdata.length && sweden.emplomentextent ? (
              <>
                <SectionImageBetween
                  image={walletSvg}
                  alt="Illustration plånbok"
                  topcolor={sectionIncomeColor}
                  bottomcolor={sectionEmplomentExtentColor}
                />
                <EmplomentExtentContainer
                  data={emplomentextentdata.filter(data => data)}
                  blockColor={sectionEmplomentExtentColor}
                  sweden={sweden}
                  scroll={scroll}
                />
              </>
            ) : (
              <div></div>
            )}

            {sweden.accommodation ? (
              <AccommodationContainer
                data={accommodationdata.filter(data => data)}
                sweden={sweden}
                blockColor={sectionAccommodationColor}
                scroll={scroll}
              />
            ) : (
              <div></div>
            )}

            {sweden.livingcondition ? (
              <>
                <SectionImageBetween
                  image={accommodationSvg}
                  alt="Illustration bostäder"
                  topcolor={sectionAccommodationColor}
                  bottomcolor={sectionPopulationLocationColor}
                />
                <LivingConditionContainer
                  data={livingconditiondata.filter(data => data)}
                  blockColor={sectionPopulationLocationColor}
                  sweden={sweden}
                  scroll={scroll}
                />
              </>
            ) : (
              <div></div>
            )}

            {taxratedata.length && sweden.taxrate ? (
              <>
                <TaxRateContainer
                  data={taxratedata.filter(data => data)}
                  blockColor={sectionTaxColor}
                  sweden={sweden}
                />
                <SectionImageBetween
                  image={taxSvg}
                  alt="Illustration valsedel"
                  topcolor={sectionTaxColor}
                  bottomcolor={sectionElectionColor}
                  scroll={scroll}
                />
              </>
            ) : (
              <div></div>
            )}
            {electiondata.length && sweden.election ? (
              <ElectionContainer
                data={electiondata.filter(data => data)}
                blockColor={sectionElectionColor}
                sweden={sweden}
                scroll={scroll}
              />
            ) : (
              <div></div>
            )}
            <EndComponent scroll={scroll} />
          </>
        ) : (
          <>
            <SectionImageBetween
              image={startPageSvg}
              alt="Illustration familj"
              topcolor={isLoadingData ? sectionPopulationColor : "#FFF"}
              bottomcolor={sectionPopulationColor}
            />
            <StartPage />
          </>
        )}
      </Wrapper>
      <Footer />
    </React.Fragment>
  );
};

const blocksHaveData = blocks => {
  let count = 0;

  blocks.forEach(arr => {
    if (!isNullorEmpty(arr)) count++;
  });

  if (count == blocks.length) return true;

  return false;
};

// const AllLayout = props => {

//     return (
//         <React.Fragment>

//         </React.Fragment>
//     );

// }

export default PageLayout;
