import React from "react";

const LinePath = props => {
  const { lineType, color, x, y} = props;

  switch (lineType) {
    case "curved-right":
      return (
        <path
          transform="translate(10,55) scale(0.7, 0.7)"
          d="M24 0.00012207C24 13.2541 13.2528 23.9971 -7.73208e-07 23.9971"
          fillOpacity="0"
          stroke={color}
          strokeMiterlimit="5"
        />
      );
    case "curved-left":
      return (
        <path
          transform="translate(20,55) scale(0.7, 0.7) rotate(90 50 50)"
          d="M24 0.00012207C24 13.2541 13.2528 23.9971 -7.73208e-07 23.9971"
          fillOpacity="0"
          stroke={color}
          strokeMiterlimit="5"
        />
      );
    case "strait":
      return (
        <path
          transform={"translate("+x+","+y+") scale(0.7, 0.7)"}
          d="M 0,0 L 0,20"
          fillOpacity="0"
          stroke={color}
        />
      );
    case "strait-double":
      return (
        <path
          transform={"translate("+x+","+y+") scale(0.6, 0.6)"}
          d="M 0,0 L 0,20"
          fillOpacity="0"
          stroke={color}
        />
      );

    default:
      return (
        <path
          transform={"translate("+x+","+y+")"}
          d="M 0,0 L 0,15"
          fillOpacity="0"
          stroke={color}
        />
      );
  }
};

LinePath.defaultProps = {
  x: 0,
  y: 0,
  color: "#000",
  lineType: ""
};

export default LinePath;

