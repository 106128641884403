import styled, { keyframes } from "styled-components";
import { device } from "./Breakpoints";
import { secondaryColor } from "./Colors";

// Max width that defines the block size (and for footer header)
export const maxWidthBlock = "900px";

//Font Sizes
export const headingSmall = "30px";
export const headingMedium = "52px";
export const headingLarge = "80px";

export const fontSizeXS = "10px";
export const fontSizeSmall = "12px";
export const fontSizeMedium = "14px";
export const fontSizeMobile = "16px";
export const fontSizeTablet = "18px";

// Font Line-Height
export const lineheightHeadingSmall = "36px";
export const lineheightHeadingMedium = "58px";
export const lineheight34 = "34px";
export const lineheight68 = "68px";
export const lineheightSmall = "26px";
export const lineheightMedium = "30px";
export const lineheightCaption = "18px";

// letter spacing
export const letterSpacingDruk = "-.025em";
export const letterSpacingPT_serif = "-.015em";

//Fonts
export const roboto = "Roboto";
export const ptSerif = "PT serif";
export const drukText = "Druk Text Web";

// Headings PT Serif

export const Heading1 = styled.h1`
    font-family: ${ptSerif}
    letter-spacing: ${letterSpacingPT_serif};
    font-size: ${props => (props.fontSize ? props.fontSize : headingSmall)}
    line-height: ${props =>
      props.lineHeight ? props.lineHeight : lineheightHeadingSmall}
    font-weight:bold;
    color:  ${props => (props.color ? props.color : secondaryColor)}; 

    @media${device.tablet} {
      font-size:${props =>
        props.fontSizeTablet ? props.fontSizeTablet : headingMedium};
      line-height: ${props =>
        props.lineHeightTablet
          ? props.lineHeightTablet
          : lineheightHeadingMedium};
    }
`;

export const Heading2 = styled.h2`
  color: ${props => (props.color ? props.color : secondaryColor)}; 
  padding-top:20px;
  font-size: 20px;
  font-family: ${ptSerif}
  line-height: 28px;
  font-style: normal;
  font-weight: bold;
`;

// Headings roboto Municipality
export const HeadingMunicipality = styled.h2.attrs({
  tabIndex: props => (props.tabIndex ? props.tabIndex : "0")
})`
  color: ${props => (props.color ? props.color : secondaryColor)};
  font-family: ${roboto};
  font-weight: bold;
  font-size: ${fontSizeTablet};
  line-height: ${lineheightMedium};
  margin: 0;
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)};
`;

export const Heading6 = styled.h6`
  color: ${props => (props.color ? props.color : secondaryColor)};
  font-family: ${roboto};
  font-weight: 400 !important;
  font-size: ${fontSizeMobile};
  line-height: ${lineheightSmall};
  margin: 0;
`;

// Block

export const SubtitleBlock = styled.h2`
    font-family: ${roboto}
    font-size: ${fontSizeMobile}
    line-height:${lineheightSmall}
    font-weight: 400 !important;
    padding-top: 5px;

    @media${device.tablet} {
      font-size: ${fontSizeTablet};
      line-height: ${lineheightMedium}
    }
`;

// Captions
export const Caption = styled.p`
  color: ${props => (props.color ? props.color : secondaryColor)};
  font-family: ${roboto};
  font-weight: normal;
  font-size: ${fontSizeSmall}
  margin-top: 2px;
  padding-top: 2px;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : "0")};

  @media${device.tablet} {
    font-size: ${fontSizeMedium}
  }
`;

export const CaptionForeignObject = styled.foreignObject`
  fill: ${props => (props.color ? props.color : secondaryColor)};
  font-family: ${roboto};
  font-weight: bold;
  font-size: 7px;
  padding-top:2px;

  @media${device.mobileM} {
    font-size:6px;
  }
  @media${device.mobileL} {
    font-size:5px;
  }

  &> span {
      display: inline-block;
    
    @media${device.mobileL} {
      display: inline;
    }
  }
`;

export const FiguresWrapper = styled.div`
  display: flex;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "100%")};
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)};
`;

export const Line = styled.hr`
  height: auto;
  border-left: 1px dashed ${secondaryColor};
  margin: 0;
  margin-top: ${props => (props.marginTop ? props.marginTop : "6px")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0px")};
  opacity: 0.4;
`;

export const HorizontalLine = styled.hr`
  height: auto;
  border-bottom: 1px dashed ${secondaryColor};
  margin: 0;
  margin-top: ${props => (props.marginTop ? props.marginTop : "0px")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0px")};
  opacity: 0.4;
`;
//Components images

export const scaleInBottomBubble = keyframes`
    from {
      transform: scale(0);
      transform-origin: 50% 50%;
      opacity: 1;
    }
    to {
      transform: scale(1);
      transform-origin: 50% 50%;
      opacity: 1;
    }
  `;

export const scaleInBottomBubbleDouble = keyframes`
    from {
      transform: scale(0);
      transform-origin: 25% 50% 0;
      opacity: 1;
    }
    to {
      transform: scale(1);
      transform-origin: 25% 50% 0;
      opacity: 1;
    }
  `;

export const scaleInBottomLine = keyframes`
    from {
        transform: translateY(0) scaleY(0);
        opacity: 1;
    }
    to {
        transform: translateY(0) scaleY(1);
        opacity: 1;
    }
`;

export const scaleInFigure = keyframes`
from {
  transform: scale(0);
  transform-origin: 25% 50% 0;
  opacity: 1;
}
to {
  transform: scale(1);
  transform-origin: 25% 50% 0;
  opacity: 1;
}
`;

export const drawLine = keyframes`
   from {
        stroke-dashoffset: -20;
   }
   to {
        stroke-dashoffset: 0;
   }
`;

export const circleTransition = keyframes`
    from {
      r: 0px;
    }
    to {
      r: 20px;
    }
`;

export const slideUp = keyframes`
from: {
  transform: translate3d(0, '100%', 0),
  visibility: 'visible'
},
to: {
  transform: translate3d(0, 0, 0)
}
`;

export const dash = keyframes`
from: {
  stroke-dashoffset: 0%;
}   to: {
  stroke-dashoffset: 100%;
}
`;

export const rotate360CW = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const rotate360CCW = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(-360deg);
}
`;
